import React from 'react';
import './RequestInstructorPage.scss';
import { SelectBox, SearchBox } from '../../Components';
import list from '../../Assets/Icon/list.svg';
import shoppinglist from '../../Assets/Icon/shopping-list.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import moment from 'moment';
import success from '../../Assets/Icon/success.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllCourses, requestInstructor } from '../../Redux/Actions';
import Calendar from 'react-calendar';
import { ReactSVG } from 'react-svg';
import Helper from '../../Utils/Helper';

const Grades = [
    '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade', '6th Grade', '7th Grade', '8th Grade', '9th Grade',
];

class RequestInstructorPage extends React.Component {
    state = {
        instructor_date: new Date(),
        course: '',
        grade: '',
        level_id: '',
        depth: 0,
        current_timezone: moment.tz.guess(true)
    };

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        };
    };

    componentDidMount() {
        if (this.props.course_list.length === 0) {
            this.props.getAllCourses();
        };
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.request_instructor_success && this.props.request_instructor_success) {
            this.props.updateTitle('push', 'Request new instructor')
        };
    };

    selectDate = (date) => {
        this.setState({ 
            instructor_date : date,
        });
    };

    onSave = () => {
        const { instructor_date, course, level_id } = this.state;
        if (!instructor_date || !course || !level_id) {
            Helper.showToast('error', 3000, 'Please select all datas!');
            return;
		};
		
		if (moment(instructor_date).diff(moment(), 'days') < 0) {
            Helper.showToast('error', 3000, "Can't select past date");
			return;
		};
        
        let index = this.props.course_list.findIndex(temp => temp.name === course);
        if (index === -1) {
            Helper.showToast('error', 3000, 'Please select a course!');
            return;
        }

        let course_id = this.props.course_list[index].id;
        
        let param = {
            level_id,
            course_id,
            start_when: instructor_date,
            tutoring_need: '',
            timezone: moment.tz(moment.utc(), this.state.current_timezone).utcOffset()/60
        };

        this.props.requestInstructor(param);
    };

    render() {
        const { course, grade, instructor_date, depth } = this.state;
        let Courses = Array.from(this.props.course_list, x => x.name);
        return (
            <div className="requestinstructor-page">
                {depth === 0 && <div className="container request-container v-r">
                    <h1>Request a New Instructor</h1>
                    <div className="option-group v-r">
                        <h2 className="option-title">When would you like to start instructing?</h2>
                        <Calendar
							className="calendar"
							onChange={this.selectDate}
							value={instructor_date}
							calendarType="US"
							prevLabel={<ReactSVG src={left} className="svg-icon" />}
							nextLabel={<ReactSVG src={right} className="svg-icon" />}
						/>
                    </div>
                    <div className="option-group v-r">
                        <h2 className="option-title">Grade Level</h2>
                        <SelectBox className="option-select" icon={list} placeholder="Select Grade Level" value={grade} options={this.props.levels} onChange={val => this.setState({grade: val.name, level_id: val.id})}/>
                    </div>
                    <div className="option-group v-r">
                        <h2 className="option-title">Course</h2>
                        <SearchBox icon={shoppinglist} value={course} placeholder="Select Course" options={Courses} onChange={val => this.setState({course: val})}/>
                    </div>
                    <button className={`btn-2 primary-btn submit-btn ${this.props.request_instructor_loading ? 'disabled' : ''}`} onClick={e => this.onSave()}>
                        {this.props.request_instructor_loading ? <div className="spinner" /> : 'Save'}
                    </button>
                </div>}
                {depth === 1 && <div className="success-area v-c h-c">
                    <div className="success-content v-r h-c v-c">
                        <h2>Success!</h2>
                        <img src={success} alt="success" />
                        <p>Thanks for your request. We’re working on matching you with the best possible tutor and you should hear back from us shortly.</p>
                    </div>
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        course_list: state.Lesson.course_list,
        request_instructor_loading: state.Instructor.request_instructor_loading,
        request_instructor_success: state.Instructor.request_instructor_success,
		levels: state.Levels.levels,
    }
}

export default connect(mapStateToProps, { 
    getAllCourses, 
    requestInstructor 
})(withRouter(RequestInstructorPage));