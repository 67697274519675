import React from 'react';
import './SettingsPage.scss';
import { InputBox, Accordion, SelectBox, Avatar } from '../../Components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateMyInfo } from '../../Redux/Actions';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';
import Helper from '../../Utils/Helper';
import _ from 'lodash'

const Menus = [
	'PROFILE', 'STUDENT INFO', 'PASSWORD', 'HOW IT WORKS'
]

const questions = [
	{ title: 'How can I allow my instructor to book session for me?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse scelerisque, neque non hendrerit mattis, risus mi condimentum justo, id consequat nulla ipsum ut erat. Maecenas eget bibendum nunc. Duis rutrum ligula at velit facilisis, a eleifend dolor consequat. Cras laoreet, neque elementum pulvinar mattis, magna nisl tincidunt dui, ut sollicitudin ipsum elit et nulla. Aliquam erat volutpat. Vivamus blandit, nulla vel tincidunt accumsan, odio massa gravida magna, a varius quam nunc vel lacus. Vivamus sollicitudin, diam eget elementum dapibus, sem sem tincidunt tortor, non efficitur lectus lacus non mauris. Nullam elementum tempus libero vel fermentum.'},
	{ title: 'What is my instructing rate?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse scelerisque, neque non hendrerit mattis, risus mi condimentum justo, id consequat nulla ipsum ut erat. Maecenas eget bibendum nunc. Duis rutrum ligula at velit facilisis, a eleifend dolor consequat. Cras laoreet, neque elementum pulvinar mattis, magna nisl tincidunt dui, ut sollicitudin ipsum elit et nulla. Aliquam erat volutpat. Vivamus blandit, nulla vel tincidunt accumsan, odio massa gravida magna, a varius quam nunc vel lacus. Vivamus sollicitudin, diam eget elementum dapibus, sem sem tincidunt tortor, non efficitur lectus lacus non mauris. Nullam elementum tempus libero vel fermentum.'},
	{ title: 'What if I miss a instructing session?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse scelerisque, neque non hendrerit mattis, risus mi condimentum justo, id consequat nulla ipsum ut erat. Maecenas eget bibendum nunc. Duis rutrum ligula at velit facilisis, a eleifend dolor consequat. Cras laoreet, neque elementum pulvinar mattis, magna nisl tincidunt dui, ut sollicitudin ipsum elit et nulla. Aliquam erat volutpat. Vivamus blandit, nulla vel tincidunt accumsan, odio massa gravida magna, a varius quam nunc vel lacus. Vivamus sollicitudin, diam eget elementum dapibus, sem sem tincidunt tortor, non efficitur lectus lacus non mauris. Nullam elementum tempus libero vel fermentum.'},
	{ title: 'What If my instructor cancels a instructing session?', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse scelerisque, neque non hendrerit mattis, risus mi condimentum justo, id consequat nulla ipsum ut erat. Maecenas eget bibendum nunc. Duis rutrum ligula at velit facilisis, a eleifend dolor consequat. Cras laoreet, neque elementum pulvinar mattis, magna nisl tincidunt dui, ut sollicitudin ipsum elit et nulla. Aliquam erat volutpat. Vivamus blandit, nulla vel tincidunt accumsan, odio massa gravida magna, a varius quam nunc vel lacus. Vivamus sollicitudin, diam eget elementum dapibus, sem sem tincidunt tortor, non efficitur lectus lacus non mauris. Nullam elementum tempus libero vel fermentum.'},
];

const Experiences = [
	{id: 1, name: 'Beginner'},
	{id: 2, name: 'Intermediate'},
	{id: 3, name: 'Advanced'},
	{id: 4, name: 'Not Sure'},
]

class SettingsPage extends React.Component {
	state = {
		menu: 'PROFILE',

		user: {},
		photo: '',

		last_password: '',
		new_password: '',
        upload_picture: ''
	}

	componentDidMount() {
		const { user, levels } = this.props;

		this.setState({
			user: { 
				...user, 
				country_code: user.country_code || 'US',
				student_grade_level_name: user.level.name,
				level_id: user.level.id,
			},
			photo: _.isEmpty(user.picture) ? '' :`${process.env.REACT_APP_IMAGE_DOMAIN}${user.picture}`
		})
		if (this.props.location.search && this.props.location.search.length > 0) {
			let section = this.props.location.search.split("=")[1];
			this.setState({
				menu: section === 'info' ? 'STUDENT INFO' : section === 'password' ? 'PASSWORD' : section === 'works' ? 'HOW IT WORKS' : 'PROFILE'
			})
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.search !== prevProps.location.search) {
			let section = this.props.location.search.length > 0 ? this.props.location.search.split("=")[1] : '';
			this.setState({
				menu: section === 'info' ? 'STUDENT INFO' : section === 'password' ? 'PASSWORD' : section === 'works' ? 'HOW IT WORKS' : 'PROFILE'
			})
		};

		if (prevProps.update_info_loading && !this.props.update_info_loading) {
			const {update_info_success, profileUpdateErr} = this.props;
			if(update_info_success){
				Helper.showToast('success', 3000, 'Successfully updating!');
				this.setState({
					last_password: '',
					new_password: ''
				});
			} else {
				Helper.showToast('error', 3000, profileUpdateErr);
			}
		};
	}

	pickFileForPhoto = (e) => {
		e.preventDefault();
		if (!e.target.files[0]) return;
		this.setState({
			photo: URL.createObjectURL(e.target.files[0]),
			user: {...this.state.user, upload_picture: e.target.files[0]}
		});
	};

	updateInfo = (field, val) => {
		this.setState({
			user: {
				...this.state.user,
				[field]: val,
			}
		})
	};

	updateStudentInfo = (field, val) => {
		this.setState({
			user: {
				...this.state.user,
				student_info: {
					...this.state.user.student_info,
					[field]: val
				}
			}
		})
	};

	updateProfile = () => {        
        const {menu, user, last_password, new_password } = this.state;
		const formData = new FormData();		
		switch (menu) {
			case 'PROFILE':
				if(user.upload_picture){
					formData.append('picture', user.upload_picture);
				};
				formData.append('first_name', user.first_name);
				formData.append('last_name', user.last_name);
				formData.append('email', user.email);
				formData.append('country_code', user.country_code);
				formData.append('phone', user.phone);
				// formData.append('address_street', user.address_street);
				// formData.append('address_zipcode', user.address_zipcode);
				this.saveInfo(formData);
			break;

			case 'STUDENT INFO':
				formData.append('parent_name', user.parent_name);
				formData.append('age', user.student_age);
				formData.append('level_id', user.level_id);
				formData.append('coding_experience', user.student_coding_experience);					
				formData.append('note', user.note);
				this.saveInfo(formData);
			break;

			case 'PASSWORD':
				formData.append('old_password', last_password);
				formData.append('password', new_password);
				this.saveInfo(formData);
			break;

			default:
				console.log("update profile")
		}
	}

	saveInfo = (formData) => {
        this.props.updateMyInfo(formData);
    };

	render() {
		const { menu, photo, last_password, new_password, user } = this.state;

		return (
			<div className="settings-page v-r">
				<div className="settings-header">
					<div className="container settings-container header-container v-c">
						{Menus.map((item, index) => <p className={`head-menu ${menu === item ? 'active' : ''}`} key={index} onClick={e => this.setState({menu: item})}>
							{item}
						</p>)}
					</div>
				</div>
				<div className="settings-body">
					<div className="container settings-container">
						{menu === 'PROFILE' && <div id="section-profile" className="v-r">
							<div className="option-group v-r picture-option">
								<h2 className="option-title">Main Picture</h2>
								<div className="photo-area v-c">
									{photo ? <img src={photo} className="avatar" alt="avatar" />
											: <Avatar className="avatar" info={user} />
									}
									<input type="file" accept="image/*" id="image_picker" style={{display:'none'}} onChange={e => this.pickFileForPhoto(e)}></input>
									<label htmlFor="image_picker"><div className="btn-2 btn-2-outline upload_btn v-c h-c">Upload Photo</div></label>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6">
									<div className="option-group v-r">
										<h2 className="option-title">First Name</h2>
										<InputBox value={user.first_name} placeholder="Type First Name" onChange={val => this.updateInfo('first_name', val)}/>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="option-group v-r">
										<h2 className="option-title">Last Name</h2>
										<InputBox value={user.last_name} placeholder="Type Last Name" onChange={val => this.updateInfo('last_name', val)}/>
									</div>
								</div>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Email</h2>
								<InputBox value={user.email} placeholder="Type Email" onChange={val =>  this.updateInfo('email', val)}/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Phone</h2>
								<div className="v-c">
									{user.country_code && <ReactFlagsSelect className="custom-flag" defaultCountry={user.country_code} onSelect={val => this.updateInfo('country_code', val)} searchable={true} showSelectedLabel={false} />}
									<InputBox value={user.phone} placeholder="Type Phone Number" onChange={val => this.updateInfo('phone', val)}/>
								</div>
							</div>
							{/* <div className="option-group v-r">
								<h2 className="option-title">Address</h2>
								<InputBox value={user.address_street} placeholder="Type Address" onChange={val =>  this.updateInfo('address_street', val)}/>
							</div>
							<div className="row">
								<div className="col-lg-4">
									<div className="option-group v-r">
										<h2 className="option-title">City</h2>
										<InputBox value={user.city} placeholder="Type City" onChange={val => this.updateInfo('city', val)}/>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="option-group v-r">
										<h2 className="option-title">State</h2>
										<InputBox value={user.state} placeholder="Type State" onChange={val => this.updateInfo('state', val)}/>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="option-group v-r">
										<h2 className="option-title">Zipcode</h2>
										<InputBox value={user.address_zipcode} placeholder="Type Zipcode" onChange={val => this.updateInfo('address_zipcode', val)}/>
									</div>
								</div>
							</div> */}
							{user.first_name && user.last_name && user.email && user.phone ?
								<button className={`btn-2 primary-btn save-btn ${this.props.update_info_loading ? 'disabled' : ''}`} onClick={e => this.updateProfile()}>
									{this.props.update_info_loading ? <div className="spinner" /> : 'Save Changes'}
								</button>					
								:
								<button className="btn-2 save-btn">
									Save Changes
								</button>
							}
						</div>}
						{menu === 'STUDENT INFO' && <div id="section-info" className="v-r">
							<div className="option-group v-r">
								<h2 className="option-title">Parent Name</h2>
								<InputBox value={user.parent_name} placeholder="Type Student Name" onChange={val =>  this.updateInfo('parent_name', val)}/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Grade Level</h2>
								<SelectBox className="option-select" value={user.student_grade_level_name} options={this.props.levels} placeholder="Select Grade" onChange={val =>  [this.updateInfo('student_grade_level_name', val.name), this.updateInfo('level_id', val.id)]}/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Age</h2>
								<InputBox value={user.student_age} placeholder="Type Student Age" type="number" onChange={val =>  this.updateInfo('student_age', val)}/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">Coding Experience</h2>
								<SelectBox className="option-select" value={user.student_coding_experience} options={Experiences} placeholder="Select Coding Experience" onChange={val =>  this.updateInfo('student_coding_experience', val.name)}/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">About Me</h2>
								<textarea className="option-note" value={user.note || ''} onChange={e => this.updateInfo('note', e.target.value)} resize="none" placeholder=""/>
							</div>
							{user.parent_name && user.student_coding_experience && user.student_age &&user.note? 
								<button className={`btn-2 primary-btn save-btn ${this.props.update_info_loading ? 'disabled' : ''}`} onClick={e => this.updateProfile()}>
									{this.props.update_info_loading ? <div className="spinner" /> : 'Save Changes'}
								</button>					
								:
								<button className="btn-2 save-btn">
									Save Changes
								</button>
							}
						</div>}						
						{menu === 'PASSWORD' && <div id="section-password" className="v-r">
							<div className="option-group v-r">
								<h2 className="option-title">Last Password</h2>
								<InputBox type="password" value={last_password} placeholder="Type Last Password" onChange={val => this.setState({last_password: val})}/>
							</div>
							<div className="option-group v-r">
								<h2 className="option-title">New Password</h2>
								<InputBox type="password" value={new_password} placeholder="Type New Password" onChange={val => this.setState({new_password: val})}/>
							</div>
							{last_password && new_password? 
								<button className={`btn-2 primary-btn save-btn ${this.props.update_info_loading ? 'disabled' : ''}`} onClick={e => this.updateProfile()}>
									{this.props.update_info_loading ? <div className="spinner" /> : 'Save Changes'}
								</button>					
								:
								<button className="btn-2 save-btn">
									Save Changes
								</button>
							}
						</div>}						
						{menu === 'HOW IT WORKS' && <div id="section-how-it" className="v-r">
							{questions.map((item, index) => <Accordion className="accordion" title={item.title} content={item.content} key={index} />)}
						</div>}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.Authentication.user,
		update_info_loading: state.Authentication.update_info_loading,
		update_info_success: state.Authentication.update_info_success,
		profileUpdateErr: state.Authentication.profileUpdateErr,

		levels: state.Levels.levels,
	}
}

export default connect(mapStateToProps, {
	updateMyInfo,
})(withRouter(SettingsPage));
