import { GET_REVIEW_LIST, GET_REVIEW_LIST_SUCCESS, GET_REVIEW_LIST_FAIL, LEAVE_REVIEW, LEAVE_REVIEW_SUCCESS, LEAVE_REVIEW_FAIL } from '../Type';

const INITIAL = {
    review_list: [],
    review_list_success: false,

    leave_review_loading: false,
    leave_review_success: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_REVIEW_LIST: 
        case GET_REVIEW_LIST_FAIL: {
            return {
                ...state,
                review_list_success: false,
            }
        }
        case GET_REVIEW_LIST_SUCCESS: {
            return {
                ...state,
                review_list: action.data || [],
                review_list_success: true,
            }
        }
        case LEAVE_REVIEW: {
            return {
                ...state,
                leave_review_loading: true,
                leave_review_success: false,
            }
        }
        case LEAVE_REVIEW_SUCCESS: {
            return {
				...state,
                leave_review_loading: false,
                leave_review_success: true
            }
        }
        case LEAVE_REVIEW_FAIL: {
            return {
                ...state,
                leave_review_loading: false,
                leave_review_success: false,
            }
        }

        default:
            return state;
    }
}