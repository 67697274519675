import { REHYDRATE } from 'redux-persist';
import { 
	AUTH_UPDATE_TOKEN, 
	GET_MY_INFO, 
	GET_MY_INFO_FAIL, 
	GET_MY_INFO_SUCCESS, 
	UPDATE_MY_INFO, 
	UPDATE_MY_INFO_FAIL, 
	UPDATE_MY_INFO_SUCCESS, 
	AUTH_UPDATE_PLAN,	
	AUTH_SIGN_OUT, 
	AUTH_DEACTIVATE_PLAN,
    GET_RECOMMENDATION, GET_RECOMMENDATION_SUCCESS, GET_RECOMMENDATION_FAIL,
} from '../Type';

const INITIAL = {
	token: '',
	isLoggedIn: false,

	user: {},

	update_info_loading: false,
	update_info_success: false,
	profileUpdateErr: null,

	plan_id: null,
	pay_per: null,
	status: 'inactive',
	recommendation: {}
}

export default (state = INITIAL, action) => {
	switch (action.type) {
		case REHYDRATE: {
			if (!action.payload || !action.payload.Authentication) return state;
			const { Authentication } = action.payload;
			const { token, isLoggedIn, user, plan_id, pay_per, status } = Authentication;

			if (token && token.length > 0) {
				sessionStorage.setItem('token', token);
				return { token, isLoggedIn, user, plan_id, pay_per, status };
			} else {
				return state;
			}
		}
		case AUTH_UPDATE_TOKEN: {
			const { token } = action.data;
			sessionStorage.setItem('token', token);
			return {
				...state,
				token,
				isLoggedIn: token && token.length > 0 ? true : false,
			}
		}
		case AUTH_SIGN_OUT: {
			sessionStorage.clear();
			return {
				...state,
				token: '',
				isLoggedIn: false,
				user: {},
				plan_id: null,
				pay_per: null,
				status: 'inactive'
			}
		}
		case GET_MY_INFO: 
		case GET_MY_INFO_FAIL: {
			return {
				...state,
				user: {}
			}
		}
		case GET_MY_INFO_SUCCESS: {
			return {
				...state,
				user: action.data
			}
		}
		case UPDATE_MY_INFO: {
			return {
				...state,
				update_info_loading: true,
				update_info_success: false
			}
		}
		case UPDATE_MY_INFO_SUCCESS: {
			return {
				...state,
				update_info_loading: false,
				update_info_success: true,
				user: action.data
			}
		}
		case UPDATE_MY_INFO_FAIL: {
			return {
				...state,
				update_info_loading: false,
				update_info_success: false,
				profileUpdateErr: action.data.error
			}
		}
		case AUTH_UPDATE_PLAN: {
			const { plan_id, pay_per, status } = action.data;
			return {
				...state,
				plan_id: plan_id, 
				pay_per: pay_per,
				status: status
			}
		}
		case AUTH_DEACTIVATE_PLAN: {
			return {
				...state,
				status: 'inactive'
			}
		}

		case GET_RECOMMENDATION: 
		case GET_RECOMMENDATION_FAIL: {
			return {
				...state,
				recommendation: {
					recommendations: [],
					tutors: []
				}
			}
		}
		case GET_RECOMMENDATION_SUCCESS: {
			return {
				...state,
				recommendation: action.data
			}
		}
		default:
				return state;
	}
}