import { 
    GET_MY_INFO,
    GET_MY_INFO_SUCCESS,
    GET_MY_INFO_FAIL,
    UPDATE_MY_INFO,
    UPDATE_MY_INFO_SUCCESS,
    UPDATE_MY_INFO_FAIL,
    GET_RECOMMENDATION, GET_RECOMMENDATION_SUCCESS, GET_RECOMMENDATION_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getMyInfo as getMyInfoApi,
    updateMyInfo as updateMyInfoApi,
    getRecommendation as getRecommendationApi,
} from '../../Api';

function* getMyInfo(payload){
    try {
        const result = yield getMyInfoApi();
        if (result && result.id > 0) {
            yield put({ type: GET_MY_INFO_SUCCESS, data: result })
        } else {
            yield put({ type: GET_MY_INFO_FAIL, data: {} })
        }
    } catch (err) {
        yield put({ type: GET_MY_INFO_FAIL, data: {} })
    }
}

export function* watchGetMyInfo(){
    yield takeLatest(GET_MY_INFO, getMyInfo)
}

function* updateMyInfo(payload){
    try {
        const result = yield updateMyInfoApi(payload.data);
        if (result && result.id > 0) {
            yield put({ type: UPDATE_MY_INFO_SUCCESS, data: result })
        } else {
            yield put({ type: UPDATE_MY_INFO_FAIL, data: result })
        }
    } catch (err) {
        yield put({ type: UPDATE_MY_INFO_FAIL, data: {} })
    }
}

export function* watchUpdateMyInfo(){
    yield takeLatest(UPDATE_MY_INFO, updateMyInfo)
}

function* getRecommendation(payload){
    try {
        const result = yield getRecommendationApi();
        if (result) {
            yield put({ type: GET_RECOMMENDATION_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_RECOMMENDATION_FAIL, data: {} })
        }
    } catch (err) {
        yield put({ type: GET_RECOMMENDATION_FAIL, data: {} })
    }
}

export function* watchGetRecommendation(){
    yield takeLatest(GET_RECOMMENDATION, getRecommendation)
}
