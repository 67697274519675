import { all, fork } from 'redux-saga/effects';
import { watchGetAllInstructors, watchgetMytutorDetail, watchdeleteTutor, watchRequestInstructor, watchGetSchools } from './Instructor';
import { watchgetBraintreekey, watchGetReceiptData, watchUpdateCard, watchgetCardInfo } from './Payment';
import { watchGetReviews, watchLeaveReview } from './Review';
import { watchGetPendingLesson, watchGetCompletedLesson, watchCancelLesson, watchGetAllCourses, watchScheduleLesson, watchgetAvailability } from './Lesson';
import { watchgetMessageUserList, watchGetContacts, watchStartNewChat, watchgetMessageDetail, watchSendMessage } from './Chat';
import { watchGetMyInfo, watchUpdateMyInfo, watchGetRecommendation } from './Authentication';
import { watchUpdatePlan, watchGetPlan, watchUnsubscribePlan } from './Plan';
import { watchGetLevels } from './Levels';



export default function* rootSaga() {
    yield all([
        fork(watchGetAllInstructors),
        fork(watchgetMytutorDetail),
        fork(watchdeleteTutor),
        
        fork(watchRequestInstructor),
        fork(watchGetSchools),
        
        fork(watchgetBraintreekey),
        fork(watchGetReceiptData),
        fork(watchgetCardInfo),        
        fork(watchUpdateCard),

        fork(watchGetReviews),
        fork(watchLeaveReview),

        fork(watchGetPendingLesson),
        fork(watchGetCompletedLesson),
        fork(watchCancelLesson),
        fork(watchGetAllCourses),
        fork(watchScheduleLesson),
        fork(watchgetAvailability),

        fork(watchgetMessageUserList),
        fork(watchGetContacts),
        fork(watchStartNewChat),
        fork(watchgetMessageDetail),
        fork(watchSendMessage),

        fork(watchGetMyInfo),
        fork(watchUpdateMyInfo),
        fork(watchGetRecommendation),

        fork(watchUpdatePlan),
        fork(watchGetPlan),
        fork(watchUnsubscribePlan),

        fork(watchGetLevels),
        
        
    ]);
}