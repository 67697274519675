import { Api } from './Api';

export function* getMyInfo(){
    const jsonData = yield Api.get("/me");
    return jsonData;
}

export function* updateMyInfo(data) {
    const jsonData = yield Api.formPost('/me', data);
    return jsonData;
}

export function* getRecommendation(){
	const jsonData = yield Api.get("/recommendations");
    return jsonData;
}
