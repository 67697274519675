import {
    GET_REVIEW_LIST, GET_REVIEW_LIST_SUCCESS, GET_REVIEW_LIST_FAIL, LEAVE_REVIEW, LEAVE_REVIEW_SUCCESS, LEAVE_REVIEW_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getReviews as getReviewsApi,
    leaveReview as leaveReviewApi,
} from '../../Api';

function* getReviews(payload){
    try {
        const result = yield getReviewsApi();        
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_REVIEW_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_REVIEW_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_REVIEW_LIST_FAIL, data: [] })
    }
}

export function* watchGetReviews(){
    yield takeLatest(GET_REVIEW_LIST, getReviews)
}

function* leaveReview(payload){
    try {
        const result = yield leaveReviewApi(payload.data);
        if (result) {
            yield put({ type: LEAVE_REVIEW_SUCCESS, data: result.data })
        } else {
            yield put({ type: LEAVE_REVIEW_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: LEAVE_REVIEW_FAIL, data: [] })
    }
}

export function* watchLeaveReview(){
    yield takeLatest(LEAVE_REVIEW, leaveReview)
}