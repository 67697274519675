import { GET_MESSAGE_USER_LIST, GET_CONTACTS_LIST, GET_MESSAGES_DETAIL, SEND_MESSAGE, GET_UNREAD_MESSAGES_LIST, START_CHAT } from '../Type';

export const getMessageUserList = () => {
  return {
    type: GET_MESSAGE_USER_LIST,
    data: {}
  }
}

export const getMessageDetail = (chat_id) => {
  return {
    type: GET_MESSAGES_DETAIL,
    data: { chat_id }
  }
}

export const sendMessage = (data) => {
  return {
    type: SEND_MESSAGE,
    data: data
  }
}




export const getContacts = () => {
  return {
    type: GET_CONTACTS_LIST,
    data: {}
  }
}


export const getUnreadMessages = (chat_id) => {
  return {
    type: GET_UNREAD_MESSAGES_LIST,
    data: { chat_id }
  }
}

export const startNewChat = (data) => {
  return {
    type: START_CHAT,
    data: data
  }
}