import { 
  GET_INSTRUCTOR_LIST,
  GET_MY_TUTOR_DETAIL,
  GET_DELETE_TUTOR,
  REQUEST_INSTRUCTOR, 
  GET_SCHOOL_LIST 
} from '../Type';

export const getAllInstructors = () => {
  return {
    type: GET_INSTRUCTOR_LIST,
    data: {}
  }
}

export const getMytutorDetail = (tutor_id) => {
  return {
    type: GET_MY_TUTOR_DETAIL,
    data: {tutor_id}
  }
}

export const deleteTutor = (tutor_id) => {
  return {
    type: GET_DELETE_TUTOR,
    data: {tutor_id}
  }
}

export const requestInstructor = (data) => {
  return {
    type: REQUEST_INSTRUCTOR,
    data: data
  }
}

export const getSchoolList = () => {
  return {
    type: GET_SCHOOL_LIST,
    data: {}
  }
}