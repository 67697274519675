import { 
    GET_INSTRUCTOR_LIST, 
    GET_INSTRUCTOR_LIST_SUCCESS, 
    GET_INSTRUCTOR_LIST_FAIL, 
    GET_MY_TUTOR_DETAIL, 
    GET_MY_TUTOR_DETAIL_SUCCESS, 
    GET_MY_TUTOR_DETAIL_FAIL, 
    GET_DELETE_TUTOR, 
    GET_DELETE_TUTOR_SUCCESS,
    GET_DELETE_TUTOR_FAIL, 
    REQUEST_INSTRUCTOR,
    REQUEST_INSTRUCTOR_SUCCESS, 
    REQUEST_INSTRUCTOR_FAIL, 
    GET_SCHOOL_LIST, 
    GET_SCHOOL_LIST_SUCCESS, 
    GET_SCHOOL_LIST_FAIL 
} from '../Type';

const INITIAL = {
    instructor_list: [],
    instructor_list_success: false,
    instructor_list_loading: true,


    myTutorDetail: null,
    tutor_detail_success: false,

    deleteTutorStatus: null,
    deleteTutorErr: '',
    delete_tutor_loading: false,


    request_instructor_loading: false,
    request_instructor_success: false,

    school_list: [],
    school_list_success: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    /**   
     * Get All Tutors
     */
    case GET_INSTRUCTOR_LIST: 
    case GET_INSTRUCTOR_LIST_FAIL: {
        return {
            ...state,
            instructor_list_success: false,
            instructor_list_loading: false
        }
    }
    case GET_INSTRUCTOR_LIST_SUCCESS: {
        return {
            ...state,
            instructor_list: action.data || [],
            instructor_list_success: true,
            instructor_list_loading: false
        }
    }

    /**
     * Get Tutor Detail
     */
    case GET_MY_TUTOR_DETAIL: 
    case GET_MY_TUTOR_DETAIL_FAIL: {
        return {
            ...state,
            tutor_detail_success: false,
        }
    }
    case GET_MY_TUTOR_DETAIL_SUCCESS: {
        return {
            ...state,
            myTutorDetail: action.data,
            tutor_detail_success: true,
        }
    }

    /**
     * Delete Tutor 
     */
    case GET_DELETE_TUTOR: 
        return {
            ...state,
            deleteTutorStatus: null,
            delete_tutor_loading: false
        }
    case GET_DELETE_TUTOR_FAIL: {
        return {
            ...state,
            deleteTutorStatus: null,
            deleteTutorErr: action.data,
            delete_tutor_loading: true
        }
    }
    case GET_DELETE_TUTOR_SUCCESS: {
        return {
            ...state,
            deleteTutorStatus: action.data,
            delete_tutor_loading: true
        }
    }

    /**
     * Request Tutor
     */    
    case REQUEST_INSTRUCTOR: {
        return {
            ...state,
            request_instructor_loading: true,
            request_instructor_success: false,
        }
    }
    case REQUEST_INSTRUCTOR_SUCCESS: {
        return {
            ...state,
            request_instructor_loading: false,
            request_instructor_success: true,
        }
    }
    case REQUEST_INSTRUCTOR_FAIL: {
        return {
            ...state,
            request_instructor_loading: false,
            request_instructor_success: false,
        }
    }





    
    case GET_SCHOOL_LIST:
    case GET_SCHOOL_LIST_FAIL: {
        return {
            ...state,
            school_list_success: false,
        }
    }
    case GET_SCHOOL_LIST_SUCCESS: {
        return {
            ...state,
            school_list: action.data || [],
            school_list_success: true,
        }
    }

    default:
      return state;
  }
}