import {
    GET_BRAINTREE_KEY, 
    GET_BRAINTREE_KEY_SUCCESS, 
    GET_BRAINTREE_KEY_FAIL,    
    GET_CARD_INFO, 
    GET_CARD_INFO_SUCCESS, 
    GET_CARD_INFO_FAIL,
    GET_RECEIPT_LIST, 
    GET_RECEIPT_LIST_SUCCESS, 
    GET_RECEIPT_LIST_FAIL,
    UPDATE_CARD_INFO, 
    UPDATE_CARD_INFO_SUCCESS, 
    UPDATE_CARD_INFO_FAIL } from '../Type';
import { data } from 'jquery';

const INITIAL = {
    braintreeKey: null,
    get_braintreeKey_loading: true,

    receipt_list: [],
    receipt_list_success: false,

    card_numbers: null,
    get_card_info_loading: true,

    update_card_loading: false,
    update_card_success: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    /**
     * GET_BRAINTREE_KEY
     */
    case GET_BRAINTREE_KEY: {
        return {
            ...state,
            braintreeKey: null,
            get_braintreeKey_loading: true
        }
    }
    case GET_BRAINTREE_KEY_FAIL: {
        return {
            ...state,
            braintreeKey: null,
            get_braintreeKey_loading: false
        }
    }
    case GET_BRAINTREE_KEY_SUCCESS: {
        return {
            ...state,
            braintreeKey: action.data,
            get_braintreeKey_loading: false
        }
    }

    /**
     * GET_CARD_INFO
     */
    case GET_CARD_INFO: {
        return {
            ...state,
            card_numbers: null,
            get_card_info_loading: true
        }
    }
    case GET_CARD_INFO_FAIL: {
        return {
            ...state,
            card_numbers: null,
            get_card_info_loading: false
        }
    }
    case GET_CARD_INFO_SUCCESS: {
        return {
            ...state,
            card_numbers: action.data,
            get_card_info_loading: false
        }
    }

    /**
     * GET_RECEIPT_LIST
     */
    case GET_RECEIPT_LIST: 
    case GET_RECEIPT_LIST_FAIL: {
        return {
            ...state,
            receipt_list_success: false,
        }
    }
    case GET_RECEIPT_LIST_SUCCESS: {
        return {
            ...state,
            receipt_list: action.data || [],
            receipt_list_success: true,
        }
    }

    /**
     * UPDATE_CARD_INFO
     */
    case UPDATE_CARD_INFO: {
        return {
            ...state,
            update_card_loading: true,
            update_card_success: false,
            update_card_message: ''
        }
    }
    case UPDATE_CARD_INFO_SUCCESS: {
        return {
            ...state,
            update_card_loading: false,
            update_card_success: true,
            update_card_message: action.message
        }
    }
    case UPDATE_CARD_INFO_FAIL: {
        return {
            ...state,
            update_card_loading: false,
            update_card_success: false,
            update_card_message: action.message
        }
    }

    default:
        return state;
  }
}