import React from 'react';
import './ScheduleLessonPage.scss';
import search from '../../Assets/Icon/search.svg';
// import cancel from '../../Assets/Icon/cancel.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, Avatar, TimeZone } from '../../Components';
import { ReactSVG } from 'react-svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import success from '../../Assets/Icon/success.png';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import { getAllInstructors, scheduleLesson, getAvailability } from '../../Redux/Actions';
import { TimeList } from './TimeList';
import Helper from '../../Utils/Helper';

const TutorRepeats = [
    {id: "1", name: "", value: 0},
    {id: "2", name: "2 Weeks", value: 1},
    {id: "3", name: "3 Weeks", value: 2},
    {id: "4", name: "1 Month", value: 3},
    {id: "5", name: "2 Months", value: 7},
    {id: "6", name: "3 Months", value: 11},
    {id: "7", name: "6 Months", value: 23},
    {id: "8", name: "9 Months", value: 35},
    {id: "9", name: "1 year", value: 51},  
];
class ScheduleLessonPage extends React.Component {
    state = {
        timeList: TimeList,
        Instructors: [],
        searchText: '',
        depth: 0,
        selectedInstructor: {},

        instructor_date: '',
        max_repeat_value: 0,
        selected_time: {},
        repeat_until: 0,

        current_timezone: moment.tz.guess(true)
    }

    componentDidMount() {
        if (this.props.instructor_list.length === 0) {
            this.props.getAllInstructors();
        } else {
            if (this.props.match.params.instructor_id) {
                let index = this.state.Instructors.findIndex(temp => temp.id === parseInt(this.props.match.params.instructor_id, 10));
                if (index !== -1) {
                    this.selectInstructor(this.state.Instructors[index]);
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.instructor_list.length === 0 && this.props.instructor_list.length > 0) {
            if (this.props.match.params.instructor_id) {
                let index = this.state.Instructors.findIndex(temp => temp.id === parseInt(this.props.match.params.instructor_id, 10));
                if (index !== -1) {
                    this.selectInstructor(this.state.Instructors[index]);
                }
            }
        };
        
        if (prevProps.schedule_lesson_loading && !this.props.schedule_lesson_loading) {
            if(this.props.schedule_lesson_success){
                this.props.updateTitle('push', 'Schedule a lesson', 3);
            } else {
                Helper.showToast('error', 3000, this.props.schedule_lesson_message)
                // return;
            };
        };

        if (prevProps.get_availability_loading && !this.props.get_availability_loading) {
            Helper.hideSpinner();
            const {availabilityTime} = this.props
            const { timeList } = this.state
            if(availabilityTime){
                timeList.map((data, index)=>{
                    timeList[index].availability = true
                    availabilityTime.availability.map((availability_data, availability_index)=>{
                        if(data.value === availability_index && !availability_data){
                            timeList[index].availability = false
                        }
                    })
                })

                this.setState({timeList})
            }
        };
    }

    formatInitialData = () => {
        this.setState({
            instructor_date: '',
            selected_time: '',
        })
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth,
            Instructors: props.instructor_list
        }
    }

    selectInstructor = async (instructor) => {
        let temp = instructor || {};
        this.setState({ 
            selectedInstructor: temp,

        });
        this.formatInitialData();
        await this.props.formatTitle();
        this.props.updateTitle('push', 'Schedule a lesson', 2);
    };

    selectTimezone = (val) => {
        this.setState({current_timezone: val})
        this.getAvailabilityTime(moment(this.state.instructor_date).format('YYYY-MM-DD'), val);
    };

    selectDate = (date) => {
        if(Math.floor((new Date() - new Date(date))) / 1000 < 0 ){
            let max_repeat_value = 0;
            if(this.props.user.plan_charge_date){
              max_repeat_value = Math.floor((new Date(this.props.user.plan_charge_date) - new Date(date)) / (7*24*3600*1000));
              if(max_repeat_value < 0)max_repeat_value = 0;
            }      
            this.setState({ 
                instructor_date : date,
                max_repeat_value: max_repeat_value,
                selected_time: '',
            });
            this.getAvailabilityTime(moment(date).format('YYYY-MM-DD'), this.state.current_timezone);
        } else {
            this.setState({ 
                instructor_date : '',
                selected_time: '',
            })
        };
    };

    getAvailabilityTime = (day, timezone) => {
        Helper.showSpinner()
        const deploy ={
            tutor_id: this.state.selectedInstructor.id,
            day: day,
            timezone: moment.tz(moment.utc(), timezone).utcOffset()/60
        }
        this.props.getAvailability(deploy);
    };

    doSchedule = (repeat_until = 0) => {
        const { selectedInstructor, instructor_date, selected_time, current_timezone, Instructors } = this.state;
        
        let tz_offset = moment.tz(moment.utc(), current_timezone).utcOffset()/60;
        let param = {
            tutor_id: selectedInstructor.id,
            sched: `${moment(instructor_date).format('YYYY-MM-DD')} ${selected_time}`,
            // hours: 1,
            repeat_until: repeat_until,
            timezone: tz_offset,
            // client_note: ''       
        }
        const tmp = Instructors.map(a =>{
            if(selectedInstructor && a.id == selectedInstructor.id)a.can_book_trial = false;
            return a;
        });
        this.setState({ Instructors: tmp, repeat_until: repeat_until });
        this.props.scheduleLesson(param);
    }

    render() {
        const { timeList, searchText, depth, selectedInstructor, Instructors, instructor_date, selected_time, current_timezone, repeat_until, max_repeat_value } = this.state;
        const { user } = this.props;
        const list = Instructors.filter(instructor => instructor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        return (
            <div className="schedulelesson-page">
                <div className={`instructor-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="instructors v-r scroll-area">
                        {list.map((item, index) => <div className={`instructor-container ${selectedInstructor.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectInstructor(item)}>
                            <div className="instructor v-c">
                                <Avatar className="avatar" info={item} />
                                <div className="info v-r">
                                    <h4>{item.name}</h4>
                                    <p>{item.school}</p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area scroll-area v-r ${depth >= 1 ? 'activate' : ''}`}>
                    {depth === 1 && <div className="date-content">
                        <div className="date-select-area v-r">
                            { selectedInstructor.can_book_trial && <h3>Your first lesson is free!</h3> }
                            <Calendar
                                className="calendar"
                                onChange={this.selectDate}
                                value={instructor_date || new Date()}
                                calendarType="US"
                                prevLabel={<ReactSVG src={left} className="svg-icon" />}
                                nextLabel={<ReactSVG src={right} className="svg-icon" />}
                            />
                            <TimeZone value={current_timezone} onChange={val => this.selectTimezone(val)}/>
                        </div>
                        {instructor_date.toString().length > 0 && <div className="time-select v-r">
                            <div className="time-header v-c v-r">
                                <h1>{moment(instructor_date).format('dddd, MMMM D, YYYY')}</h1>
                                <p>Please select start time</p>
                            </div>
                            <div className="time-list v-r">
                                {timeList.map((item, index) => 
                                    !item.availability? 
                                    null:
                                    <div className={`time-container v-c ${selected_time === item.time24 ? 'active' : ''}`} key={index}>
                                        <div className="time-item sub-item v-c h-c" onClick={e => this.setState({selected_time: item.time24})}>{moment(item.time24, ["HH:mm"]).format("hh:mm A")}</div>
                                        {TutorRepeats.filter(item => (!selectedInstructor.can_book_trial && item.value <= max_repeat_value) || (selectedInstructor.can_book_trial && item.value == 0)).map(item =>
                                        <div className={`confirm-item sub-item v-c h-c ${this.props.schedule_lesson_loading ? 'disabled' : ''}`} onClick={e => this.doSchedule(item.value)}>
                                            {this.props.schedule_lesson_loading && repeat_until == item.value ? <div className="spinner" /> : (selectedInstructor.can_book_trial ? 'Book Trial Lesson' : 'Book '+item.name)}
                                        </div>
                                        )}
                                    </div>
                            )}
                            </div>
                        </div>}
                    </div>}
                    {depth >= 2 && <div className="schedule-options v-r">
                        <div className="selected-date v-c">
                            <ReactSVG src={left} className="svg-icon show-web-flex" onClick={e => this.props.updateTitle('pop')} />
                            <h2>{moment(instructor_date).format('dddd, MMMM D')}, Start Time: {moment(selected_time, ["HH:mm"]).format("hh:mm A")}, {current_timezone}</h2>
                        </div>
                        <div className="success-area v-c h-c">
                            <div className="success-content v-r h-c v-c">
                                <h2>Success!</h2>
                                <img src={success} alt="success" />
                                <p>Thanks for scheduling a lesson. Your  tutor will be notify by email and SMS.</p>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        instructor_list: state.Instructor.instructor_list,
        schedule_lesson_success: state.Lesson.schedule_lesson_success,
        schedule_lesson_loading: state.Lesson.schedule_lesson_loading,
        schedule_lesson_message: state.Lesson.schedule_lesson_message,
        get_availability_loading: state.Lesson.get_availability_loading,
        availabilityTime: state.Lesson.availabilityTime,
		user: state.Authentication.user,
    }
}

export default connect(mapStateToProps, { 
    getAllInstructors, 
    scheduleLesson,
    getAvailability
})(withRouter(ScheduleLessonPage));