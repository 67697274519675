import React from 'react';
import './CompletedLessonPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, RoundButton, SelectBox } from '../../Components';
import search from '../../Assets/Icon/search.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
// import video from '../../Assets/Video/video.mp4';
import play_button from '../../Assets/Icon/play-button.svg';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import { toggleVideoModal, getCompletedLesson } from '../../Redux/Actions';

class CompletedLessonPage extends React.Component { 
    state = {
        id: '',
        data: [],
        filteredList: [],
        
        searchText: '',
        currentPage: 1,
        totalPage: 1,
        displayCount: 10,

        depth: 0,

        current_timezone: moment.tz.guess(true)

    }

    static getDerivedStateFromProps(props, state) {
        const id = (props.match.params.id && props.match.params.id.length > 0) ? props.match.params.id : '';
        return {
            id: id,
            depth: props.depth,
            data: props.completed_lesson_list
        };
    }

    componentDidMount() {
        const { current_timezone } = this.state;
        const timezone = moment.tz(moment.utc(), current_timezone).utcOffset()/60;
        this.props.completed_lesson_list.length === 0 ? this.props.getCompletedLesson(timezone) : this.filterData('', 10);
    }

    componentDidUpdate(prevProps, prevState) {
        const prevID = (prevProps.match.params.id && prevProps.match.params.id.length > 0) ? prevProps.match.params.id : '';
        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
        if (prevID === newID && prevID !== '' && newID !== '') {
            if (this.state.depth !== 1 && prevState.depth === 0) {
                this.props.updateTitle('push', 'Completed Lessons', 2);
            }
        }
        if (prevProps.completed_lesson_list.length === 0 && this.props.completed_lesson_list.length > 0) {
            this.filterData(this.state.searchText, this.state.displayCount);
        }
    }

    changeDisplayCount = (val) => {
        const { searchText } = this.state;
        this.filterData(searchText, val);
    }

    changeSearchText = (val) => {
        const { displayCount } = this.state;
        this.filterData(val, displayCount);
    }

    changeCurrentPage = (direction) => {
        const { currentPage, totalPage } = this.state;
        if ((direction === 'previous' && currentPage === 1) || (direction === 'next' && currentPage === totalPage)) return;
        this.setState({
            currentPage: direction === 'next' ? currentPage + 1 : currentPage - 1
        })
    }

    filterData = (searchText, displayCount) => {
        const { data } = this.state;
        const filteredList = data.filter(item => item.tutor.name.indexOf(searchText) > -1);
        const totalPage = Math.ceil(filteredList.length / displayCount);

        this.setState({
            searchText: searchText,
            filteredList: filteredList,
            totalPage: totalPage,
            displayCount: displayCount,
            currentPage: 1
        })
    }

    viewItem = (item) => {
        this.props.history.push(`/completed-lesson/${item.id}`);
    }

    openVideoModal = (item) => {
        this.props.toggleVideoModal(true, item.actions);
    }

    navigateToInstructor = (instructor_id) => {
        this.props.history.push(`/instructors/${instructor_id}`);
    }

    checkLessonExpired = (lesson_date) => {
		let lesson = moment(lesson_date);
		return moment().diff(lesson) > 0;
    };
    
    seeRecording = (item) => {
        if(item.recorded_online_link){
            window.open(item.recorded_online_link);
        };
    };

    render() {
        const { searchText, displayCount, filteredList, currentPage, totalPage, depth } = this.state;
        const startIndex = displayCount * (currentPage - 1);
        const displayList = filteredList.slice(startIndex, startIndex + displayCount);

        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
        let selectedData = {};
        if (newID !== '') {
            let index = filteredList.findIndex(temp => temp.id === parseInt(newID, 10));
            selectedData = filteredList[index] || {};
        }

        return (
            <div className="completedlesson-page v-r">
                {depth === 0 && <div className="options-area">
                    <InputBox className="search-input" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.changeSearchText(val)} />
                    <div className="count-area v-c">
                        <RoundButton icon={left} className="show-mobile-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <p className="page-count">Page {currentPage} of {totalPage  }</p>
                        <RoundButton icon={left} className="arrow-btn show-web-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeCurrentPage('next')}/>
                        <div className="combo-area v-c">
                            <p>Items</p>
                            <SelectBox value={displayCount} onChange={val => this.changeDisplayCount(val.name)}/>
                        </div>
                    </div>
                </div>}
                {depth === 0 && <div className="data-area v-r">
                    <div className="data-header show-web-flex v-c">
                        <div className="cell cell-1">TUTOR</div>
                        <div className="cell cell-2">SCHEDULE</div>
                        <div className="cell cell-3">HOURS</div>                        
                        <div className="cell cell-5">CANCELLED</div>
                        <div className="cell cell-6">ACTIONS</div>
                    </div>
                    <div className="data-list show-web-flex v-r">
                        {displayList.map((item, index) => <div className="data-row" key={index}>
                            <div className="cell cell-1"><p style={{cursor:'pointer'}} onClick={e => this.navigateToInstructor(item.tutor.id)}>{item.tutor.name}</p></div>
                            <div className="cell cell-2"><span style={{cursor:'pointer'}} onClick={e => this.viewItem(item)}>{moment(item.sched).local().format('MMM D, YYYY hh:mm a')}</span></div>
                            <div className="cell cell-3">{item.hours}</div>
                            <div className="cell cell-5">{item.cancel_time ? item.cancel_time : 'NO'}</div>
                            <div className="cell cell-6">
                                <div className={`v-c ${item.actions ? 'active' : ''}`}>
                                    {!item.cancel_time ? <React.Fragment>
                                        <div className="v-c" onClick={e => this.seeRecording(item)}>
                                            <ReactSVG src={play_button} className="svg-icon"/> See Recording
                                        </div>
                                    </React.Fragment> : 'No link to online session'}
                                </div>
                            </div>
                        </div>)}
                    </div>
                    <div className="show-mobile-flex v-r">
                        {displayList.map((item, index) => <div className="mobile-row v-r" key={index}>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">INSTRUCTOR</div> <p className="active" onClick={e => this.navigateToInstructor(item.tutor.id)}>{item.tutor.name}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">SCHEDULE</div> <p className="active" onClick={e => this.viewItem(item)}>{moment(item.sched).local().format('MMM D, YYYY hh:mm a')}</p> </div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">HOURS</div> <p>{item.duration}</p> </div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">CANCELLED</div> <p>{item.cancelled ? 'YES' : 'NO'}</p> </div>
                                <div className="right-col v-r"> 
                                    <div className={`v-c ${item.actions ? 'active' : ''}`}>
                                        {!item.cancel_time ? <div className="v-c" onClick={e => this.seeRecording(item)}><ReactSVG src={play_button} className="svg-icon"/> See Recording</div> : 'No link to online session'}
                                    </div>
                                 </div>
                            </div>
                        </div>)}
                    </div>
                </div>}
                {depth === 1 && <div className="completed-detail v-r">
                    <h1 className="show-web">Instructing Session</h1>
                    {selectedData.id > 0 && <div className="detail-list v-r">
                        <div className="detail-row v-c">
                            <div className="detail-title">Instructor</div>
                            <div className="detail-content name" style={{cursor:'pointer'}} onClick={e => this.navigateToInstructor(selectedData.tutor.id)}>{selectedData.tutor.name}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Start Time</div>
                            <div className="detail-content">{moment(selectedData.sched).local().format('MMM D, YYYY hh:mm a')}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Length</div>
                            <div className="detail-content">{selectedData.hours}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Booked on</div>
                            <div className="detail-content">{moment(selectedData.booked_on).local().format('MMM D, YYYY hh:mm a')}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Cost</div>
                            <div className="detail-content">{`$${selectedData.amount}`}</div>
                        </div>
                    </div>}
                    <h1> Description </h1>
                    <div className="group">
                        <h2>Client</h2>
                        <p>{selectedData.client_note}</p>
                    </div>
                    <div className="group">
                        <h2>Instructor</h2>
                        <p>{selectedData.tutor_note}</p>
                    </div>
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        completed_lesson_list: state.Lesson.completed_lesson_list
    }
};

export default connect(mapStateToProps, { toggleVideoModal, getCompletedLesson })(withRouter(CompletedLessonPage));