import arrowLeft from './Assets/Icon/arrow-left.png';
import arrowRight from './Assets/Icon/arrow-right.png';

import avatar1 from './Assets/Avatar/avatar1.png';
import avatar2 from './Assets/Avatar/avatar2.png';
import avatar3 from './Assets/Avatar/avatar3.png';
import avatar4 from './Assets/Avatar/avatar4.png';
import avatar5 from './Assets/Avatar/avatar5.png';

const Avatars = [avatar1, avatar2, avatar3, avatar4, avatar5];

export const StarSVGViewBox = "0 0 16 15";
export const StarSVGIconPath = "M7.90577 0.587492L9.82622 5.13287L14.7428 5.55524C15.0838 5.58469 15.2225 6.01017 14.9637 6.23414L11.2344 9.46512L12.3519 14.2717C12.4294 14.6057 12.0675 14.8684 11.7746 14.6909L7.54927 12.1427L3.32397 14.6909C3.03025 14.8676 2.6691 14.6049 2.7466 14.2717L3.86415 9.46512L0.134072 6.23337C-0.124778 6.00939 0.0131721 5.58392 0.354947 5.55447L5.27155 5.13209L7.192 0.587492C7.3253 0.271292 7.77247 0.271292 7.90577 0.587492Z";

export const CarouselLeftButton = `
    <div style='width:50px;height:50px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowLeft}' alt='nav-arrow' />
    </div>
`;
export const CarouselRightButton = `
    <div style='width:50px;height:50px;border-radius:50%;display:flex;align-items:center;justify-content:center;background:white;box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);'>
        <img src='${arrowRight}' alt='nav-arrow' />
    </div>
`;

export const STRIPE_API_KEY= "pk_test_1mmpj1OCbdawd5tVNv8IiRHh";

export const PlanPrices = [
    [
        [199, 179.1, 159.2],
        [299, 269.1, 239.2]
    ],
    [
        [350, 315, 280],
        [450, 405, 360]
    ]
];

export const GetAvatar = (origin) => {
	let index = Math.floor(Math.random() * 10) % 5;
	return origin === 'https://icodeedu.com/storage/users/default.png' ? Avatars[index] : origin
}