import React from 'react';
import './JoinLesson.scss';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleJoinLesson } from '../../Redux/Actions';

class JoinLesson extends React.Component {
    timer = null;
    state = {
        isOpen: false,
        info: {}, // seconds
        second: 10,
        isRecommendationOpen: true,
    }

    componentDidUpdate() {
        const { isOpen } = this.state;
        if (isOpen) {
            if (!this.timer) {
                this.timer = setInterval(() => {
                    let { second } = this.state;
                    if (second > 0) {
                        this.setState({ second: this.state.second - 1 })
                    } else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.props.toggleJoinLesson(false);
                    }
                }, 1000);
            }
        } else {
            clearInterval(this.timer);
            this.timer = null;
        } 
    }

    static getDerivedStateFromProps(props, state) {
        return {
            isOpen: props.isOpen,
            info: props.info || {},
            second: props.isOpen !== state.isOpen ? 10 : state.second
        }
    }

    joinLesson = () => {
        clearInterval(this.timer);
        this.timer = null;
        this.props.toggleJoinLesson(false);
    }

    clickRecommendation = () => {
        this.setState({isRecommendationOpen: false});
    }

    formatTime = (sec) => {
        let second = sec;
        const hour = Math.floor(second / 3600);
        second = second - hour * 3600;
        const minute = Math.floor(second / 60);
        second = second - minute * 60;
        return `${hour < 10 ? '0'+hour : hour}:${minute < 10 ? '0'+minute : minute}:${second < 10 ? '0'+second : second}`;
    }

    render() {
        const { isOpen, second, isRecommendationOpen } = this.state;
        return (<div>
            <div className={`joinlesson-component ${isOpen ? 'active' : 'inactive'} `}>
                <p>Online instructing starts in {this.formatTime(second)}</p>
                <button className="join-btn" onClick={e => this.joinLesson()}>Join</button>
            </div>
            <div className={`joinlesson-component ${isRecommendationOpen ? 'active' : 'inactive'} `}>
                <Link style={{color: 'white'}} onClick={e => this.clickRecommendation()} to="/referral">Get FREE $200 In Tutoring Credit & Give FREE Trial Class</Link>
            </div>
        </div> );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Lesson.isOpen,
        info: state.Lesson.info,
    }
}
  
export default connect(mapStateToProps, { toggleJoinLesson })(withRouter(JoinLesson));
