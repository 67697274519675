import { GET_LEVELS, GET_LEVELS_SUCCESS, GET_LEVELS_FAIL } from '../Type';

const INITIAL = {
    levels: [],
    get_leves_loading: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {
        case GET_LEVELS: {				
            return {
                get_leves_loading: true,
            }
        }
        case GET_LEVELS_SUCCESS: {
            return {
                ...state,
                get_leves_loading: false,
            }
        }
        case GET_LEVELS_FAIL: {
            return {
                ...state,
                levels: action.data,
                get_leves_loading: false
            }
        }
        default:
            return state;
    }
}