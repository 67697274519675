import { GET_BRAINTREE_KEY, GET_CARD_INFO, GET_RECEIPT_LIST, UPDATE_CARD_INFO } from '../Type';

export const getReceiptData = () => {
  return {
    type: GET_RECEIPT_LIST,
    data: {}
  }
}

export const getCardInfo = () => {
  return {
    type: GET_CARD_INFO,
    data: {}
  }
}

export const getBraintreekey = () => {
  return {
    type: GET_BRAINTREE_KEY,
    data: {}
  }
}

export const updateCard = (data) => {
  return {
    type: UPDATE_CARD_INFO,
    data: data
  }
}