import {
    GET_BRAINTREE_KEY, 
    GET_BRAINTREE_KEY_SUCCESS, 
    GET_BRAINTREE_KEY_FAIL, 
    GET_CARD_INFO, 
    GET_CARD_INFO_SUCCESS, 
    GET_CARD_INFO_FAIL, 
    GET_RECEIPT_LIST,    
    GET_RECEIPT_LIST_SUCCESS, 
    GET_RECEIPT_LIST_FAIL, 
    UPDATE_CARD_INFO, 
    UPDATE_CARD_INFO_SUCCESS, 
    UPDATE_CARD_INFO_FAIL,
} from '../Type';
import _ from 'lodash'

import { takeLatest, put } from 'redux-saga/effects';
import {
    getBraintreekey as getBraintreekeyApi,
    getCardInfo as getCardInfoApi,
    getReceiptData as getReceiptDataApi,
    updateCard as updateCardApi,
} from '../../Api';

function* getBraintreekey(){
    try {
        const result = yield getBraintreekeyApi();
        if (!result.error) {
            yield put({ type: GET_BRAINTREE_KEY_SUCCESS, data: result.token })
        } else {
            yield put({ type: GET_BRAINTREE_KEY_FAIL, data: null })
        }
    } catch (err) {
        yield put({ type: GET_BRAINTREE_KEY_FAIL, data: null })
    }
}

export function* watchgetBraintreekey(){
    yield takeLatest(GET_BRAINTREE_KEY, getBraintreekey)
}

function* getReceiptData(payload){
    try {
        const result = yield getReceiptDataApi();
        if (!_.isEmpty(result.data)) {
            yield put({ type: GET_RECEIPT_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_RECEIPT_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_RECEIPT_LIST_FAIL, data: [] })
    }
}

export function* watchGetReceiptData(){
    yield takeLatest(GET_RECEIPT_LIST, getReceiptData)
}

function* getCardInfo(){
    try {
        const result = yield getCardInfoApi();
        if (!result.error) {
            yield put({ type: GET_CARD_INFO_SUCCESS, data: result.card })
        } else {
            yield put({ type: GET_CARD_INFO_FAIL, data: null })
        }
    } catch (err) {
        yield put({ type: GET_CARD_INFO_FAIL, data: null })
    }
}

export function* watchgetCardInfo(){
    yield takeLatest(GET_CARD_INFO, getCardInfo)
}

function* updateCard(payload){
    try {
        const result = yield updateCardApi(payload.data);
        if (!result.error) {
            yield put({ type: UPDATE_CARD_INFO_SUCCESS, message: result.message })
        } else {
            yield put({ type: UPDATE_CARD_INFO_FAIL, message: result.message })
        }
    } catch (err) {
        yield put({ type: UPDATE_CARD_INFO_FAIL, data: [] })
    }
}

export function* watchUpdateCard(){
    yield takeLatest(UPDATE_CARD_INFO, updateCard)
}