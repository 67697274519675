import coin from '../../Assets/Icon/coin.svg';
import cup from '../../Assets/Icon/cup.svg';
import ping from '../../Assets/Icon/ping.svg';
import profile from '../../Assets/Icon/profile.svg';
import envelope from '../../Assets/Icon/envelope.svg';
import menu from '../../Assets/Icon/menu.svg';

export const Menus = [
    {
        id: 2, name: 'My Lessons', link: '', opened: false, icon: menu, subItemActive: false,
        children: [
            { name: 'Pending', link: '/pending-lesson'},
            { name: 'Completed', link: '/completed-lesson'},
        ],
    },
    {
        id: 3, name: 'My Instructors', link: '/', opened: false, children: [], icon: profile, subItemActive: false,
    },
    {
        id: 4, name: 'Message', link: '/message', opened: false, children: [], icon: envelope, subItemActive: false,
    },
    {
        id: 5, name: 'Review Instructors', link: '/review-instructors', opened: false, children: [], icon: ping, subItemActive: false,
    },
    {
        id: 6, name: 'Rate Plan', link: '/rate-plan', opened: false, children: [], icon: cup, subItemActive: false,
	},
	{
        id: 1, name: 'Payment', link: '', opened: false, icon: coin, subItemActive: false,
        children: [
            { name: 'Receipt', link: '/receipt'},
            { name: 'Credit Card', link: '/credit-card'},
        ],
    },
    {
        id: 7, name: 'Referral', link: '/referral', opened: false, children: [], icon: profile, subItemActive: false,
    },
];