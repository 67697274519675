/**
 * Sidebar
 */
export const MOBILE_SIDEBAR_TOGGLE            = 'MOBILE_SIDEBAR_TOGGLE';
export const SIDEBAR_TOGGLE                   = 'SIDEBAR_TOGGLE';

/**
 * Modal
 */
export const VIDEO_MODAL_TOGGLE               = 'VIDEO_MODAL_TOGGLE';
export const CREDIT_MODAL_TOGGLE              = 'CREDIT_MODAL_TOGGLE';
export const REVIEW_MODAL_TOGGLE              = 'REVIEW_MODAL_TOGGLE';

/**
 * Lesson
 */
export const JOIN_LESSON_TOGGLE               = 'JOIN_LESSON_TOGGLE';

export const GET_PENDING_LESSON               = 'GET_PENDING_LESSON';
export const GET_PENDING_LESSON_SUCCESS       = 'GET_PENDING_LESSON_SUCCESS';
export const GET_PENDING_LESSON_FAIL          = 'GET_PENDING_LESSON_FAIL';

export const GET_COMPLETED_LESSON             = 'GET_COMPLETED_LESSON';
export const GET_COMPLETED_LESSON_SUCCESS     = 'GET_COMPLETED_LESSON_SUCCESS';
export const GET_COMPLETED_LESSON_FAIL        = 'GET_COMPLETED_LESSON_FAIL';

export const CANCEL_LESSON                    = 'CANCEL_LESSON';
export const CANCEL_LESSON_SUCCESS            = 'CANCEL_LESSON_SUCCESS';
export const CANCEL_LESSON_FAIL               = 'CANCEL_LESSON_FAIL';

export const GET_ALL_COURSES                  = 'GET_ALL_COURSES';
export const GET_ALL_COURSES_SUCCESS          = 'GET_ALL_COURSES_SUCCESS';
export const GET_ALL_COURSES_FAIL             = 'GET_ALL_COURSES_FAIL';

export const SCHEDULE_LESSON                  = 'SCHEDULE_LESSON';
export const SCHEDULE_LESSON_SUCCESS          = 'SCHEDULE_LESSON_SUCCESS';
export const SCHEDULE_LESSON_FAIL             = 'SCHEDULE_LESSON_FAIL';

export const GET_AVAILABILITY                  = 'GET_AVAILABILITY';
export const GET_AVAILABILITY_SUCCESS          = 'GET_AVAILABILITY_SUCCESS';
export const GET_AVAILABILITY_FAIL             = 'GET_AVAILABILITY_FAIL';

/**
 * Authentication
 */
export const AUTH_UPDATE_TOKEN                = 'AUTH_UPDATE_TOKEN';
export const AUTH_UPDATE_PLAN                 = 'AUTH_UPDATE_PLAN';
export const AUTH_DEACTIVATE_PLAN							= 'AUTH_DEACTIVATE_PLAN';
export const AUTH_SIGN_OUT					  				= 'AUTH_SIGN_OUT';

export const GET_MY_INFO                      = 'GET_MY_INFO';
export const GET_MY_INFO_SUCCESS              = 'GET_MY_INFO_SUCCESS';
export const GET_MY_INFO_FAIL                 = 'GET_MY_INFO_FAIL';

export const UPDATE_MY_INFO                   = 'UPDATE_MY_INFO';
export const UPDATE_MY_INFO_SUCCESS           = 'UPDATE_MY_INFO_SUCCESS';
export const UPDATE_MY_INFO_FAIL              = 'UPDATE_MY_INFO_FAIL';

export const UPDATE_PASSWORD                  = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS          = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL             = 'UPDATE_PASSWORD_FAIL';

/**
 * Instructor
 */
export const GET_INSTRUCTOR_LIST              = 'GET_INSTRUCTOR_LIST';
export const GET_INSTRUCTOR_LIST_SUCCESS      = 'GET_INSTRUCTOR_LIST_SUCCESS';
export const GET_INSTRUCTOR_LIST_FAIL         = 'GET_INSTRUCTOR_LIST_FAIL';

export const GET_MY_TUTOR_DETAIL              = 'GET_MY_TUTOR_DETAIL';
export const GET_MY_TUTOR_DETAIL_SUCCESS      = 'GET_MY_TUTOR_DETAIL_SUCCESS';
export const GET_MY_TUTOR_DETAIL_FAIL         = 'GET_MY_TUTOR_DETAIL_FAIL';

export const GET_DELETE_TUTOR              = 'GET_DELETE_TUTOR';
export const GET_DELETE_TUTOR_SUCCESS      = 'GET_DELETE_TUTOR_SUCCESS';
export const GET_DELETE_TUTOR_FAIL         = 'GET_DELETE_TUTOR_FAIL';

export const REQUEST_INSTRUCTOR               = 'REQUEST_INSTRUCTOR';
export const REQUEST_INSTRUCTOR_SUCCESS       = 'REQUEST_INSTRUCTOR_SUCCESS';
export const REQUEST_INSTRUCTOR_FAIL          = 'REQUEST_INSTRUCTOR_FAIL';

export const GET_SCHOOL_LIST                  = 'GET_SCHOOL_LIST';
export const GET_SCHOOL_LIST_SUCCESS          = 'GET_SCHOOL_LIST_SUCCESS';
export const GET_SCHOOL_LIST_FAIL             = 'GET_SCHOOL_LIST_FAIL';

/**
 * Payment
 */
export const GET_BRAINTREE_KEY                 = 'GET_BRAINTREE_KEY';
export const GET_BRAINTREE_KEY_SUCCESS         = 'GET_BRAINTREE_KEY_SUCCESS';
export const GET_BRAINTREE_KEY_FAIL            = 'GET_BRAINTREE_KEY_FAIL';

export const GET_RECEIPT_LIST                 = 'GET_RECEIPT_LIST';
export const GET_RECEIPT_LIST_SUCCESS         = 'GET_RECEIPT_LIST_SUCCESS';
export const GET_RECEIPT_LIST_FAIL            = 'GET_RECEIPT_LIST_FAIL';

export const UPDATE_CARD_INFO                 = 'UPDATE_CARD_INFO';
export const UPDATE_CARD_INFO_SUCCESS         = 'UPDATE_CARD_INFO_SUCCESS';
export const UPDATE_CARD_INFO_FAIL            = 'UPDATE_CARD_INFO_FAIL';

export const GET_CARD_INFO                 = 'GET_CARD_INFO';
export const GET_CARD_INFO_SUCCESS         = 'GET_CARD_INFO_SUCCESS';
export const GET_CARD_INFO_FAIL            = 'GET_CARD_INFO_FAIL';


/**
 * Review
 */
export const GET_REVIEW_LIST                  = 'GET_REVIEW_LIST';
export const GET_REVIEW_LIST_SUCCESS          = 'GET_REVIEW_LIST_SUCCESS';
export const GET_REVIEW_LIST_FAIL             = 'GET_REVIEW_LIST_FAIL';

export const LEAVE_REVIEW                     = 'LEAVE_REVIEW';
export const LEAVE_REVIEW_SUCCESS             = 'LEAVE_REVIEW_SUCCESS';
export const LEAVE_REVIEW_FAIL                = 'LEAVE_REVIEW_FAIL';

/**
 * Chat
 */
export const GET_MESSAGE_USER_LIST             = 'GET_MESSAGE_USER_LIST';
export const GET_MESSAGE_USER_LIST_SUCCESS     = 'GET_MESSAGE_USER_LIST_SUCCESS';
export const GET_MESSAGE_USER_LIST_FAIL        = 'GET_MESSAGE_USER_LIST_FAIL';

export const GET_MESSAGES_DETAIL                = 'GET_MESSAGES_DETAIL';
export const GET_MESSAGES_DETAIL_SUCCESS        = 'GET_MESSAGES_DETAIL_SUCCESS';
export const GET_MESSAGES_DETAIL_FAIL           = 'GET_MESSAGES_DETAIL_FAIL';

export const GET_CONTACTS_LIST                = 'GET_CONTACTS_LIST';
export const GET_CONTACTS_LIST_SUCCESS        = 'GET_CONTACTS_LIST_SUCCESS';
export const GET_CONTACTS_LIST_FAIL           = 'GET_CONTACTS_LIST_FAIL';


export const SEND_MESSAGE                     = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS             = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAIL                = 'SEND_MESSAGE_FAIL';

export const GET_UNREAD_MESSAGES_LIST         = 'GET_UNREAD_MESSAGES_LIST';
export const GET_UNREAD_MESSAGES_LIST_SUCCESS = 'GET_UNREAD_MESSAGES_LIST_SUCCESS';
export const GET_UNREAD_MESSAGES_LIST_FAIL    = 'GET_UNREAD_MESSAGES_LIST_FAIL';

export const START_CHAT                       = 'START_CHAT';
export const START_CHAT_SUCCESS               = 'START_CHAT_SUCCESS';
export const START_CHAT_FAIL                  = 'START_CHAT_FAIL';

/**
 * Plan
 */
export const UPDATE_PLAN                      = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS              = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAIL                 = 'UPDATE_PLAN_FAIL';
export const UNSUBSCRIBE_PLAN									= 'UNSUBSCRIBE_PLAN';

export const GET_PLAN                         = 'GET_PLAN';
export const GET_PLAN_SUCCESS                 = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAIL                    = 'GET_PLAN_FAIL';

/**
 * Levels
 */
export const GET_LEVELS                      = 'GET_LEVELS';
export const GET_LEVELS_SUCCESS              = 'GET_LEVELS_SUCCESS';
export const GET_LEVELS_FAIL                 = 'GET_LEVELS_FAIL';

/**
 * Recommendation - Growth engine
 */
export const GET_RECOMMENDATION = "GET_RECOMMENDATION";
export const GET_RECOMMENDATION_SUCCESS = "GET_RECOMMENDATION_SUCCESS";
export const GET_RECOMMENDATION_FAIL = "GET_RECOMMENDATION_FAIL";

