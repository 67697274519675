import React from 'react';
import './CreditCardPage.scss';
import master from '../../Assets/CreditCard/master.png';
import visa from '../../Assets/CreditCard/visa.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateCard, getBraintreekey } from '../../Redux/Actions';
import { braintainService} from '../../services/paymentServices';
import Helper from '../../Utils/Helper';

class CreditCardPage extends React.Component { 
    state = {
        card_number_1: '',
        card_number_2: '',
        card_number_3: '',
        card_number_4: '',
        month: '',
        year: '',
        cvv2:'',        
    };

    componentDidMount() {        
        if(!this.props.braintreeKey){
            this.props.getBraintreekey();
        };
    };


    saveChange = () => {
        const { card_number_1, card_number_2, card_number_3, card_number_4, month, year, cvv2 } = this.state;
        if(card_number_1 && card_number_2 && card_number_3 && card_number_4 && month && year && cvv2){
            Helper.showSpinner();
    
            var cardDetail = {
                card_number: `${card_number_1}${card_number_2}${card_number_3}${card_number_4}`,
                month,
                year,
                cvv2,
                zip_code: '12345'
            };
            const { braintreeKey } = this.props;
            braintainService(braintreeKey, cardDetail).then((res)=>{
                this.sendNonce(res);
            })
            .catch(err => {
                Helper.hideSpinner();
                Helper.showToast('error', 300, "Invalid Card Info");
            });
        } else {
            Helper.showToast('error', 300, "Invalid Card Info");
        };
    };

    sendNonce = (nonce) => {
        const formData = new FormData()
        formData.append('todo', 'new');
        formData.append('nonce', nonce);
    
        this.props.updateCard(formData);
    };

    componentDidUpdate(prevProps) {
        
        if (prevProps.update_card_loading && !this.props.update_card_loading) {
            Helper.hideSpinner();
            const { update_card_success, update_card_message } = this.props;
            if(update_card_success){
                Helper.showToast('success', 3000, update_card_message);
            } else {
                Helper.showToast('error', 3000, update_card_message);
            };
        };
    }

    render() {
        const { error } = this.state;
        return (
            <div className="creditcard-page v-c h-c v-r">
                <p>Change Your Credit Card</p>
                <div className="box-container v-r">
                    <div className="card-box v-r">
                        <p>Card Number</p>
                        <div className="row">
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="4" onChange={e => this.setState({card_number_1 : e.currentTarget.value})}/> </div>
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="4" onChange={e => this.setState({card_number_2 : e.currentTarget.value})}/> </div>
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="4" onChange={e => this.setState({card_number_3 : e.currentTarget.value})}/> </div>
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="4" onChange={e => this.setState({card_number_4 : e.currentTarget.value})}/> </div>
                        </div>
                        <p className="second-line">Date validity</p>
                        <div className="row">
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="2" onChange={e => this.setState({month : e.currentTarget.value})}/> <div className="slash">/</div> </div>
                            <div className="col-3 sub-col"> <input className="sub-input" maxLength="2" onChange={e => this.setState({year : e.currentTarget.value})}/> </div>
                            <div className="col-3 sub-col v-c h-c"> <img src={master} alt="master" /> </div>
                            <div className="col-3 sub-col v-c h-c"> <img src={visa} alt="visa" /> </div>
                        </div>
                    </div>
                    <div className="card-box v-r">
                        <div className="break-line" />
                        <div className="row second-line">
                            <div className="col-3 sub-col" /><div className="col-3 sub-col" /><div className="col-3 sub-col" />
                            <div className="col-3 sub-col h-c v-r"> <p>CVV2</p> <input className="sub-input" maxLength="4" onChange={e => this.setState({cvv2 : e.currentTarget.value})}/> </div>
                        </div>
                    </div>
                </div>
                <button className="btn-2 btn-2-outline save-btn" onClick={e => this.saveChange()}>Save Changes</button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        update_card_loading: state.Payment.update_card_loading,
        update_card_success: state.Payment.update_card_success,
        update_card_message: state.Payment.update_card_message,

        braintreeKey: state.Payment.braintreeKey,
        get_braintreeKey_loading: state.Payment.get_braintreeKey_loading,
    }
}

export default connect(mapStateToProps, { 
    updateCard,
    getBraintreekey,
 })(withRouter(CreditCardPage));