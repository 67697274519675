import React from 'react';
import './ReceiptPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputBox, RoundButton, SelectBox } from '../../Components';
import search from '../../Assets/Icon/search.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import logo from '../../Assets/Header/logo.png';
import { getReceiptData } from '../../Redux/Actions';
import moment from 'moment';

class ReceiptPage extends React.Component { 
    state = {
        id: '',
        data: [],
        filteredList: [],
        
        searchText: '',
        currentPage: 1,
        totalPage: 1,
        displayCount: 10,

        depth: 0,
    }

    static getDerivedStateFromProps(props, state) {
        const id = (props.match.params.id && props.match.params.id.length > 0) ? props.match.params.id : '';
        return {
            id: id,
            depth: props.depth,
            data: props.receipt_list
        };
    }

    componentDidMount() {
        this.props.getReceiptData();
        this.filterData(this.state.searchText, this.state.displayCount);
    }

    componentDidUpdate(prevProps, prevState) {
        const prevID = (prevProps.match.params.id && prevProps.match.params.id.length > 0) ? prevProps.match.params.id : '';
        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
        if (prevID === newID && prevID !== '' && newID !== '') {   
            if (this.state.depth !== 1 && prevState.depth === 0) { this.props.updateTitle('push', 'Receipt', 2); }
        }
        if (prevProps.receipt_list.length === 0 && this.props.receipt_list.length > 0) {
            this.filterData(this.state.searchText, this.state.displayCount);
        }
    }

    changeDisplayCount = (val) => {
        const { searchText } = this.state;
        this.filterData(searchText, val);
    }

    changeSearchText = (val) => {
        const { displayCount } = this.state;
        this.filterData(val, displayCount);
    }

    changeCurrentPage = (direction) => {
        const { currentPage, totalPage } = this.state;
        if ((direction === 'previous' && currentPage === 1) || (direction === 'next' && currentPage === totalPage)) return;
        this.setState({
            currentPage: direction === 'next' ? currentPage + 1 : currentPage - 1
        })
    }

    filterData = (searchText, displayCount) => {
        const { data } = this.state;
        const filteredList = data.filter(item => item.description.indexOf(searchText) > -1);
        const totalPage = Math.ceil(filteredList.length / displayCount);

        this.setState({
            searchText: searchText,
            filteredList: filteredList,
            totalPage: totalPage,
            displayCount: displayCount,
            currentPage: 1
        })
    }

    viewItem = (item) => {
        this.props.history.push(`/receipt/${item.id}`);
    }

    render() {
        const { user } = this.props;
        const { searchText, displayCount, filteredList, currentPage, totalPage, depth } = this.state;
        const startIndex = displayCount * (currentPage - 1);
        const displayList = filteredList.slice(startIndex, startIndex + displayCount);
        
        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
        let selectedReceipt = {};
        if (newID !== '') {
            let index = filteredList.findIndex(temp => temp.id === parseInt(newID, 10));
            selectedReceipt = filteredList[index] || {};
        }
        return (
            <div className="receipt-page v-r">
                {depth === 0 && <div className="options-area">
                    <InputBox className="search-input" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.changeSearchText(val)} />
                    <div className="count-area v-c">
                        <RoundButton icon={left} className="show-mobile-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <p className="page-count">Page {currentPage} of {totalPage  }</p>
                        <RoundButton icon={left} className="arrow-btn show-web-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeCurrentPage('next')}/>
                        <div className="combo-area v-c">
                            <p>Items</p>
                            <SelectBox value={displayCount} onChange={val => this.changeDisplayCount(val.name)}/>
                        </div>
                    </div>
                </div>}
                {depth === 0 && <div className="data-area">
                     <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th width="10%">AMOUNT</th>
                                <th width="10%">OLD CREDIT</th>
                                <th width="10%">NEW CREDIT</th>
                                <th width="10%">PAYMENT STATUS</th>
                                <th width="20%">NOTE</th>
                                <th width="20%">DATE</th>
                                <th width="10%">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayList.map((item, index) => <tr key={index}>
                                <td>{item.amount}</td>                                
                                <td>{item.old_credit}</td>
                                <td>{item.new_credit}</td>
                                <td>{item.status}</td>
                                <td>{item.description}</td>
                                <td>{moment(item.payment_date).local().format("MMM D, YYYY hh:mm a")}</td>
                                <td>
                                    <div className="view-btn" onClick={e => this.viewItem(item)}>View</div>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                    <div className="shoe-mobile-flex v-r">
                        {displayList.map((item, index) => <div className="mobile-row v-r" key={index}>
                            <div className="data-row v-c">
                                <div className="left-col v-r"> <div className="col-header">AMOUNT</div> <p>{item.amount}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">BONUS (DISCOUNT)</div> <p>{item.bonus}</p> </div>
                            </div>
                            <div className="data-row v-c">
                                <div className="left-col v-r"> <div className="col-header">NEW CREDIT</div> <p>{item.new_credit}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">OLD CREDIT</div> <p>{item.old_credit}</p> </div>
                            </div>
                            <div className="data-row v-c">
                                <div className="left-col v-r"> <div className="col-header">PAYMENT STATUS</div> <p>{item.status}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">DATE</div> <p>{moment(item.payment_date).format("MMM D, YYYY hh:mm a")}</p> </div>
                            </div>
                            <div className="data-row v-c">
                                <div className="right-col v-r"> <div className="col-header">NOTE</div> <p>{item.description}</p> </div>
                            </div>
                            <div className="data-row v-c">
                                <div className="right-col v-r"> <div className="col-header">ACTION</div> <p className="view-btn" onClick={e => this.viewItem(item)}>View</p> </div>
                            </div>
                        </div>)}
                    </div>
                </div>}
                {depth === 1 && <div className="receipt-detail v-r">
                    <div className="detail-row">    
                        <div className="detail-block">
                            <h1>Sale Receipt</h1>
                            <div className="paragraph">
                                <p>Date: {moment(selectedReceipt.payment_date).local().format('MMM D, YYYY')}</p>
                                <p>Receipt #: {selectedReceipt.id}</p>
                            </div>
                        </div>
                    </div>
                    <div className="detail-row" style={{borderBottom: 'none'}}>
                        <div className="detail-block left-block">
                            <h1>iCode Edu</h1>
                            <div className="paragraph">
                                <p>1751 River Run Suite 200</p>
                                <p>Fort Worth, TX 76107</p>
                            </div>
                            <div className="paragraph">
                                <p>Phone: (817) 663 1308</p>
                                <p>Fax: (877) 904 0134</p>
                            </div>
                            <div className="paragraph">
                                <p>Email: info@icodeedu.com</p>
                            </div>
                        </div>
                        <div className="detail-block">
                            <h1>{user.name}</h1>
                            <div className="paragraph">
                                <p>{user.id}</p>
                            </div>
                            <div className="paragraph">
                                <p>Phone: {user.phone}</p>
                            </div>
                            <div className="paragraph">
                                <p>Email: {user.email}</p>
                            </div>
                        </div>
                    </div>
                    <div className="pay-table v-r">
                        <div className="pay-header v-c">
                            <h2 className="first-col">PAID</h2>
                            <h2>BONUS</h2>
                            <h2 className="last-col">STATUS</h2>
                        </div>
                        <div className="pay-data v-c">
                            <p className="first-col">{selectedReceipt.amount < 0 ? `-$${-selectedReceipt.amount}` : selectedReceipt.amount}</p>
                            <p>$0</p>
                            <p className="last-col completed">{selectedReceipt.status}</p>
                        </div>
                    </div>
                    <div className="receipt-footer">
                        <img src={logo} alt="logo" />
                        <p>Thank you for your business!</p>
                    </div>
                    {/* <button className="btn-2 btn-2-outline print-btn">Print</button> */}
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        receipt_list: state.Payment.receipt_list,
		user: state.Authentication.user,
    }
}

export default connect(mapStateToProps, { getReceiptData })(withRouter(ReceiptPage));