/* @flow */

import { combineReducers } from 'redux';

import Authentication from './Authentication';
import Sidebar from './Sidebar';
import Modal from './Modal';
import Lesson from './Lesson';
import Instructor from './Instructor';
import Payment from './Payment';
import Review from './Review';
import Chat from './Chat';
import Plan from './Plan';
import Levels from './Levels';

export default combineReducers ({
    Authentication,
    Sidebar,
    Modal,
    Lesson,
    Instructor,
    Payment,
    Review,
    Chat,
    Plan,
    Levels
})