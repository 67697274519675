import { VIDEO_MODAL_TOGGLE, CREDIT_MODAL_TOGGLE, REVIEW_MODAL_TOGGLE } from '../Type';

const INITIAL = {
  isVideoModalOpen: false,
  isCreditModalOpen: false,
  isReviewModalOpen: false,
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case VIDEO_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isVideoModalOpen: isOpen, info };
    }

    case CREDIT_MODAL_TOGGLE: {
      const { isOpen } = action.data;
      return { ...state, isCreditModalOpen: isOpen };
    }

    case REVIEW_MODAL_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isReviewModalOpen: isOpen, info };
    }

    default:
      return state;
  }
}