import React from 'react';
import './SelectBox.scss';
import { ReactSVG } from 'react-svg'
import down from '../../Assets/Icon/arrow-down.svg'

export default class SelectBox extends React.Component {
    state = {
        showMenu: false,
        value: '',
        options: [],
        className: '',
        icon: '',
        placeholder: ''
    }

    componentDidMount() {
        this.setState({
            value: this.props.value || '',
            options: this.props.options || [
                {id: 1, name : 10}, 
                {id: 2, name : 20}, 
                {id: 3, name : 50}, 
                {id: 4, name : 100}, 
            ],
            className: this.props.className,
            icon: this.props.icon || '',
            placeholder: this.props.placeholder || ''
        })
    }

    showMenu = (event, flag) => {
        event.stopPropagation();
        this.setState({
            showMenu: flag
        })
    }

    selectItem = (item) => {
        this.setState({
            value: item.name,
            showMenu: false
        })
        this.props.onChange(item);
    }

    render() {
        const { className, showMenu, value, options, icon, placeholder } = this.state;
        return (
            <div className={`selectbox-component ${className || ''}`}>
                <div className="selectbox-container" onClick={e => this.showMenu(e, !showMenu)} onBlur={e => this.showMenu(e, false)} tabIndex={0}> 
                    {icon && <ReactSVG src={icon} className="svg-icon start-icon"/>}
                    <p>{(value) ? value : <span className="placeholder">{placeholder}</span>}</p>
                    <ReactSVG src={down} className="svg-icon"/>
                </div>
                {showMenu && <div className="options"><div>
                    {options.map((item, index) => <div className="option" key={index} onMouseDown={e => this.selectItem(item)}>
                        {item.name}
                    </div>)}
                </div></div>}
            </div> 
        );
    }
}
