import React from 'react';
import './CardInputBox.scss';
import { ReactSVG } from 'react-svg'
import NumberFormat from 'react-number-format';

export default class CardInputBox extends React.Component {
    state = {
        value: ''
    }

    // componentDidMount() {
    //     this.setState({
    //         value: this.props.value || ''
    //     })
    // }

    changeText = (e) => {
        this.setState({
            value: e.currentTarget.value
        })
        this.props.onChange(e.currentTarget.value);
    }

    render() {
        const { className, icon, placeholder, type, value } = this.props;
        const format = value.startsWith('3') ? '#### ###### #####' : '#### #### #### ####';// amex card or other
        return (
            <div className={`card-inputbox-container v-c ${className || ''}`}>
                {icon && <ReactSVG src={icon} className="svg-icon" />}               
                <NumberFormat 
                    className="card-input-container"
                    placeholder="Card Number"
                    format={format}
                    mask="_"
                    value={value} 
                    onChange={e => this.changeText(e)}
                />
            </div>
        );
    }
}
