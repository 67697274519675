import { 
    GET_PLAN,
    GET_PLAN_FAIL,
    GET_PLAN_SUCCESS,
    UPDATE_PLAN, 
    UPDATE_PLAN_SUCCESS, 
    UPDATE_PLAN_FAIL, 
    UNSUBSCRIBE_PLAN 
} from '../Type';

const INITIAL = {

    get_plans_loading: true,
    plans: [],

    update_success: false,
    update_error_msg: '',
    update_loading: false,
}

export default (state = INITIAL, action) => {
    switch (action.type) {

        case GET_PLAN: {
            return {
                ...state,                
                get_plans_loading: true,
                plans: []
            }
        }
        case GET_PLAN_FAIL: {
            return {
                ...state,                
                get_plans_loading: false,
                plans: []
            }
        }
        case GET_PLAN_SUCCESS: {
            return {
                ...state,                
                get_plans_loading: false,
                plans: action.data
            }
        }


        case UPDATE_PLAN: 
        case UNSUBSCRIBE_PLAN: {
            return {
                ...state,
                update_success: false,
                update_error_msg: '',
                update_loading: true,
            }
        }
        case UPDATE_PLAN_SUCCESS: {
            return {
                ...state,
                update_success: true,
                update_error_msg: '', 
                update_loading: false,
            }
        }
        case UPDATE_PLAN_FAIL: {
            return {
                ...state,
                update_success: false,
                update_error_msg: action.data.msg,
                update_loading: false
            }
        }
        default:
            return state;
    }
}