import React from 'react';
import './ReviewModal.scss';
import { Modal } from 'react-bootstrap';
import { toggleReviewModal } from '../../Redux/Actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../Assets/Icon/add.svg';
import { ReactSVG } from 'react-svg'
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';
import StarRatings from 'react-star-ratings';
import { leaveReview, getMyInfo } from '../../Redux/Actions';
import Helper from '../../Utils/Helper';

class ReviewModal extends React.Component {
    state = {
        isOpen: false,
        presentation: 0,
        reviewText: '',
        connection_id: '',
        picture: '',
        first_name: ''
    }

    componentDidUpdate(prevProps){
        if (!prevProps.leave_review_success && this.props.leave_review_success) {
            Helper.showToast('success', 3000, 'Review Updated');
            this.props.getMyInfo();       
        };
    };

    static getDerivedStateFromProps(props, state) {        
        return {
            isOpen: props.isOpen,
            connection_id: props.isOpen !== state.isOpen ? props.info.connection_id : state.connection_id,
            picture: props.isOpen !== state.isOpen && props.info.picture?  props.info.picture : state.picture,
            first_name: props.isOpen !== state.isOpen ? props.info.first_name : state.first_name,
        }
    }

    closeModal = () => {
        this.props.toggleReviewModal(false);
    }

    onHide = () => {
    }

    sendReview = () => {
        let { connection_id, reviewText, presentation } = this.state;
        if (!reviewText) {
            Helper.showToast('error', 3000, 'Please type your review');
        } else {
            this.props.toggleReviewModal(false);
            let param = {
                tutor_id: connection_id,
                description: reviewText,
                rating: presentation,
                _method: 'PUT'
            }
            this.props.leaveReview(param);            
        }
    }

    render() {
        const { isOpen, presentation, reviewText, connection_id, picture, first_name } = this.state;
        return (
            <Modal show={isOpen} onHide={this.onHide} className="review-modal-component">
                <div className="review-modal-content">
                    <div className="close-btn"> <ReactSVG src={ add } className='icon-close' onClick={ () => this.closeModal() }/> </div>
                    <div className="img-area">
                        <div className="bg" />
                        {picture && <img className="picture" src={picture} alt="avatar" />}
                    </div>
                    <div className="content-area v-r">
                        <h1>Leave {first_name} A Review</h1>
                        <p>{`We are constantly striving to improve and we'd love to hear from you regarding  your tutoring experience with ${first_name}.`}</p>
                        <div className="v-r v-c star-area mt-2">
                            <p>Presentation of content</p>
                            <StarRatings
                                rating={presentation}
                                starDimension="32px"
                                starSpacing="4.5px"
                                starRatedColor="#23A4EF"
                                starHoverColor="#23A4EF"
                                starEmptyColor="#D3EDFC"
                                svgIconViewBox={StarSVGViewBox}
                                svgIconPath={StarSVGIconPath}
                                changeRating={(val) => this.setState({presentation: val})}
                            />
                        </div>
                        <h2>Your Comment</h2>
                        <textarea placeholder="" resize="none" value={reviewText} onChange={e => this.setState({reviewText: e.target.value})}/>
                        <button className="btn-2 primary-btn" onClick={e => this.sendReview()}>Save Changes</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isOpen: state.Modal.isReviewModalOpen,
        info: state.Modal.info,

        leave_review_success: state.Review.leave_review_success,
    }
}
  
export default connect(mapStateToProps, { 
    toggleReviewModal,
    leaveReview,
    getMyInfo
})(withRouter(ReviewModal));
