import { 
  JOIN_LESSON_TOGGLE, GET_PENDING_LESSON, GET_PENDING_LESSON_SUCCESS, GET_COMPLETED_LESSON, GET_COMPLETED_LESSON_SUCCESS, GET_COMPLETED_LESSON_FAIL, 
  GET_PENDING_LESSON_FAIL, CANCEL_LESSON, CANCEL_LESSON_SUCCESS, CANCEL_LESSON_FAIL, GET_ALL_COURSES, GET_ALL_COURSES_FAIL, GET_ALL_COURSES_SUCCESS, 
  SCHEDULE_LESSON, SCHEDULE_LESSON_SUCCESS, SCHEDULE_LESSON_FAIL,
  GET_AVAILABILITY, GET_AVAILABILITY_SUCCESS, GET_AVAILABILITY_FAIL,
} from '../Type';
import { act } from 'react-dom/test-utils';

const INITIAL = {
  isOpen: false,

  pending_lesson_list: [],
  pending_lesson_success: false,
  get_pending_lesson_loading: false,

  completed_lesson_list: [],
  completed_lesson_success: false,

  cancel_lesson_success: false,
  cancel_lesson_loading: false,
  cancel_lesson_message: '',

  course_list: [],
  get_course_success: false,

  schedule_lesson_success: false,
  schedule_lesson_loading: false,
  schedule_lesson_message: '',

  get_availability_success: false,
  get_availability_loading: false,
  availabilityTime: []
}

export default (state = INITIAL, action) => {
  switch (action.type) {
    case JOIN_LESSON_TOGGLE: {
      const { isOpen, info } = action.data;
      return { ...state, isOpen, info };
    }
    case GET_PENDING_LESSON: {
      // return {
      //   ...state,
      //   pending_lesson_success: false,
      //   get_pending_lesson_loading: true,
      // }
    }
    case GET_PENDING_LESSON_FAIL: {
      return {
        ...state,
        pending_lesson_success: false,
        get_pending_lesson_loading: false,

      }
    }
    case GET_PENDING_LESSON_SUCCESS: {
      return {
        ...state,
        pending_lesson_list: action.data || [],
        pending_lesson_success: true,
        get_pending_lesson_loading: false,

      }
    }
    case GET_COMPLETED_LESSON: 
    case GET_COMPLETED_LESSON_FAIL: {
      return {
        ...state,
        completed_lesson_success: false,
      }
    }
    case GET_COMPLETED_LESSON_SUCCESS: {
      return {
        ...state,
        completed_lesson_list: action.data || [],
        completed_lesson_success: true,
      }
    }
    case CANCEL_LESSON: {
      return {
        ...state,
        cancel_lesson_loading: true,
        cancel_lesson_success: false,
        cancel_lesson_message: '',
      }
    }
    case CANCEL_LESSON_SUCCESS: {
      return {
        ...state,
        cancel_lesson_loading: false,
        cancel_lesson_success: true,
        cancel_lesson_message: action.message,
      }
    }
    case CANCEL_LESSON_FAIL: {
      return {
        ...state,
        cancel_lesson_loading: false,
        cancel_lesson_success: false,
        cancel_lesson_message: action.message,
      }
    }
    case GET_ALL_COURSES:
    case GET_ALL_COURSES_FAIL: {
      return {
        ...state,
        course_list: [],
        get_course_success: false,
      }
    }
    case GET_ALL_COURSES_SUCCESS: {
      let course_list = action.data || [];
      for (let item of course_list) {
        item.age_min = item.grade.min === 'Pre-K' ? 5 : item.grade.min + 5;
        item.age_max = item.grade.max === null ? null : item.grade.max + 5;
      }
      return {
        ...state,
        course_list: course_list,
        get_course_success: true,
      }
    }
    case SCHEDULE_LESSON: {
      return {
        ...state,
        schedule_lesson_loading: true,
        schedule_lesson_success: false,
        schedule_lesson_message: ''
      }
    }
    case SCHEDULE_LESSON_SUCCESS: {
      return {
        ...state,
        schedule_lesson_loading: false,
        schedule_lesson_success: true,
        schedule_lesson_message: ''
      }
    }
    case SCHEDULE_LESSON_FAIL: {
      return {
        ...state,
        schedule_lesson_loading: false,
        schedule_lesson_success: false,
        schedule_lesson_message: action.msg
      }
    }

    case GET_AVAILABILITY: {
      return {
        ...state,
        get_availability_loading: true,
        get_availability_success: false,        
      }
    }
    case GET_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        get_availability_loading: false,
        get_availability_success: true,
        availabilityTime: action.data
      }
    }
    case GET_AVAILABILITY_FAIL: {
      return {
        ...state,
        get_availability_loading: false,
        get_availability_success: false,
        availabilityTime: action.data
      }
    }

    default:
      return state;
  }
}