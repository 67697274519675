import { Api } from './Api';

export function* getLessons(type, timezone){
    const jsonData = yield Api.get(`/transactions?status=${type}&timezone=${timezone}`);
    return jsonData;
}

export function* cancelLesson(data, id) {
    const jsonData = yield Api.formPost(`/transactions/${id}`, data);
    return jsonData;
}

export function* getAllCourses(){
    const jsonData = yield Api.get("/courses");
    return jsonData;
}

export function* scheduleLesson(data){
    const jsonData = yield Api.post("/transactions", data);
    return jsonData;
}

export function* getAvailability(data){
    const jsonData = yield Api.get(`/transactions/availability?tutor_id=${data.tutor_id}&day=${data.day}&timezone=${data.timezone}`);
    return jsonData;
}