import { VIDEO_MODAL_TOGGLE, CREDIT_MODAL_TOGGLE, REVIEW_MODAL_TOGGLE } from '../Type';

export const toggleVideoModal = (isOpen, info = null) => {
  return {
    type: VIDEO_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}

export const toggleCreditModal = (isOpen) => {
  return {
    type: CREDIT_MODAL_TOGGLE,
    data: { isOpen }
  }
}

export const toggleReviewModal = (isOpen, info = {}) => {
  return {
    type: REVIEW_MODAL_TOGGLE,
    data: { isOpen, info }
  }
}