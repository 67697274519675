import { Api } from './Api';

export function* getBraintreekey(){
    const jsonData = yield Api.get("/payments/braintreekey");
    return jsonData;
}
export function* getReceiptData(){
    const jsonData = yield Api.get("/payments?payment_type=1");
    return jsonData;
}

export function* getCardInfo(){
    const jsonData = yield Api.get("/payments/braintreecard");
    return jsonData;
}

export function* updateCard(data) {
    const jsonData = yield Api.formPost("/payments/braintreecard", data);
    return jsonData;
}
