import { UPDATE_PLAN, GET_PLAN, UNSUBSCRIBE_PLAN } from '../Type';

export const updatePlan = (data) => {
  return {
    type: UPDATE_PLAN,
    data: data
  }
}

export const getPlan = () => {
  return {
    type: GET_PLAN,
    data: {}
  }
}

export const unsubscribePlan = (data) => {
	return {
		type: UNSUBSCRIBE_PLAN,
		data: data,
	}
}