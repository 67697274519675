import React from 'react';
import './MyInstructorsPage.scss';
import search from '../../Assets/Icon/search.svg';
import cancel from '../../Assets/Icon/cancel.svg';
import { InputBox, ClientFeedback, Avatar, ConfirmModal } from '../../Components';
import { ReactSVG } from 'react-svg';
import bank from '../../Assets/Icon/bank-building.svg';
import email from '../../Assets/Icon/email.svg';
import telephone from '../../Assets/Icon/telephone.svg';
import instructorBlog from '../../Assets/Instructor/instructor_blog.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllInstructors, getMytutorDetail, deleteTutor, getAllCourses } from '../../Redux/Actions';
import Helper from '../../Utils/Helper';
import _ from 'lodash'

function innerFunc(item) {
    return {__html: item}
}

const Menus = [
    'ABOUT ME', 'MY SKILLS', 'QUALIFICATION', 'REVIEWS', 'BLOG'
]

const Blogs = [
    { thumb: instructorBlog, title: 'Lorem ipsum dolor sit amet', content: 'I took 1 semester of anatomy and 2 semesters of Physiology and received an A in all of the courses.' },
    { thumb: instructorBlog, title: 'Lorem ipsum dolor sit amet', content: 'I took 1 semester of anatomy and 2 semesters of Physiology and received an A in all of the courses.' },
    { thumb: instructorBlog, title: 'Lorem ipsum dolor sit amet', content: 'I took 1 semester of anatomy and 2 semesters of Physiology and received an A in all of the courses.' },
    { thumb: instructorBlog, title: 'Lorem ipsum dolor sit amet', content: 'I took 1 semester of anatomy and 2 semesters of Physiology and received an A in all of the courses.' },
    { thumb: instructorBlog, title: 'Lorem ipsum dolor sit amet', content: 'I took 1 semester of anatomy and 2 semesters of Physiology and received an A in all of the courses.' },
    { thumb: instructorBlog, title: 'Lorem ipsum dolor sit amet', content: 'I took 1 semester of anatomy and 2 semesters of Physiology and received an A in all of the courses.' },
    { thumb: instructorBlog, title: 'Lorem ipsum dolor sit amet', content: 'I took 1 semester of anatomy and 2 semesters of Physiology and received an A in all of the courses.' },
    { thumb: instructorBlog, title: 'Lorem ipsum dolor sit amet', content: 'I took 1 semester of anatomy and 2 semesters of Physiology and received an A in all of the courses.' },
]

class MyInstructorsPage extends React.Component {
    state = {
        Instructors: [],
        searchText: '',
        depth: 0,
        selectedInstructor: {},

        menu: 'ABOUT ME',
        show_review: 3,
        show_blog: 4,
        myTutorDetail: '',
        isOpenModal: false
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth,
            Instructors: props.instructor_list
        }
    }

    componentDidMount() {
        this.props.getAllCourses()
        
        if (this.props.instructor_list.length === 0) {
            this.props.getAllInstructors();
        } else {
            if (this.props.match.params.id) {
                let index = this.state.Instructors.findIndex(temp => temp.id === parseInt(this.props.match.params.id, 10));
                if (index !== -1) {
                    this.selectInstructor(this.state.Instructors[index]);
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.instructor_list.length === 0 && this.props.instructor_list.length > 0) {
            if (this.props.match.params.id) {
                let index = this.state.Instructors.findIndex(temp => temp.id === parseInt(this.props.match.params.id, 10));
                if (index !== -1) {
                    this.selectInstructor(this.state.Instructors[index]);
                }
            }
        };

        if(prevProps.myTutorDetail !== this.props.myTutorDetail){
            this.setState({myTutorDetail: this.props.myTutorDetail});
        };
        
        if(!prevProps.delete_tutor_loading && this.props.delete_tutor_loading){
            Helper.hideSpinner();
            const { deleteTutorStatus, deleteTutorErr } = this.props
            if(deleteTutorStatus){
                Helper.showToast('success', 3000, deleteTutorStatus);

                this.props.instructor_list.splice(this.state.delectIndex, 1);
                    
                if (this.state.delectItem.id === this.state.selectedInstructor.id) {
                    this.setState({ 
                        selectedInstructor: {},
                    });
                    this.props.formatTitle();
                }
            } else {
                Helper.showToast('error', 3000, deleteTutorErr);
            }
        };

    }

    selectInstructor = async (instructor) => {
        let temp = instructor || {};
        this.setState({ 
            selectedInstructor: temp,
        });
        await this.props.formatTitle();
        this.props.updateTitle('push', temp.name, 2);
        this.props.getMytutorDetail(instructor.id)
    }

    deleteInstructor = (e, item, index) => {
        this.setState({isOpenModal: true})
        e.stopPropagation();
        this.setState({delectE: e});
        this.setState({delectItem: item});
        this.setState({delectIndex: index});

        // e.stopPropagation();
        // let temp = this.state.Instructors;
        // let index = temp.findIndex(a => a.id === item.id)
        // temp.splice(index, 1);
        // this.setState({
        //     Instructors: temp,
        // })
        // if (item.id === this.state.selectedInstructor.id) {
        //     this.props.formatTitle();
        // }
    }

    deleteConfirm =(deleteStatus) => {
        this.setState({isOpenModal: false});
        this.setState({loading: true})
        const {delectE, delectItem, delectIndex} = this.state
        if(deleteStatus){
            Helper.showSpinner();
            delectE.stopPropagation();
            this.props.deleteTutor(delectItem.id);
        }
    }

    bookInstructor = (instructor_id) => {
        this.props.history.push(`/schedule-lesson/${instructor_id}`);
	}
	
	getCourse = (subject_id) => {
		let index = this.props.course_list.findIndex(temp => temp.id === subject_id);
		return index === -1 ? '' : this.props.course_list[index].name
	}

    renderInstructorInfo = () => {
        const { selectedInstructor } = this.state;
        return (<React.Fragment>
            <div className="instructor-photo v-r v-c">
                <Avatar className="avatar" info={selectedInstructor} />
                <h1>{selectedInstructor.name}</h1>
                <p>{selectedInstructor.profile_title}</p>
            </div>
            <div className="instructor-info v-r">
                <div className="sub-info">
                    <ReactSVG src={bank} className="svg-icon" />
                    <p>{selectedInstructor.school}</p>
                </div>
                <div className="sub-info">
                    <ReactSVG src={telephone} className="svg-icon" />
                    <p>{selectedInstructor.phone}</p>
                </div>
                <div className="sub-info">
                    <ReactSVG src={email} className="svg-icon" style={{marginTop:'auto',marginBottom: 'auto'}} />
                    <p>{selectedInstructor.email}</p>
                </div>
                <button className="btn-2 primary-btn" onClick={e => this.bookInstructor(selectedInstructor.id)}>Book Me Now</button>
            </div>
        </React.Fragment>)
    }

    render() {
        const { searchText, depth, selectedInstructor, Instructors, menu, show_review, show_blog, myTutorDetail } = this.state;
        const list = Instructors.filter(instructor => instructor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        
        return (
            <div className="myinstructors-page">
                <div className={`instructor-list v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="instructors v-r scroll-area">
                        {list.map((item, index) => <div className={`instructor-container ${selectedInstructor.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectInstructor(item)}>
                            <div className="instructor v-c">
                                <Avatar className="avatar" info={item} />
                                <div className="info v-r">
                                    <h4>{item.name}</h4>
                                    <p>{item.school}</p>
                                </div>
                                <ReactSVG src={cancel} className="svg-icon" onClick={e => this.deleteInstructor(e, item, index)} />
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`content-area ${depth >= 1 ? 'activate' : ''}`}>
                    {selectedInstructor.id > 0 && <div className="instructor-profile show-web-flex web">
                        {this.renderInstructorInfo()}
                    </div>}
                    {selectedInstructor.id > 0 && <div className="instructor-content v-r">
                        <div className="menu-header v-c">
                            <div className="menu-container v-c">
                                {/* {Menus.map((item, index) => <p className={`head-menu ${menu === item ? 'active' : ''}`} key={index} onClick={e => this.setState({menu: item})}>
                                    {item}
                                </p>)} */}
                                <p className={`head-menu ${menu === "ABOUT ME" ? 'active' : ''}`} onClick={e => this.setState({menu: "ABOUT ME"})}>
                                    ABOUT ME
                                </p>
                                {(myTutorDetail && myTutorDetail.skills.length > 0) && 
                                    (<p className={`head-menu ${menu === "MY SKILLS" ? 'active' : ''}`} onClick={e => this.setState({menu: "MY SKILLS"})}>
                                        MY SKILLS
                                    </p>)
                                }
                                {(myTutorDetail && myTutorDetail.qualifications.length > 0) && 
                                    <p className={`head-menu ${menu === "QUALIFICATION" ? 'active' : ''}`} onClick={e => this.setState({menu: "QUALIFICATION"})}>
                                        QUALIFICATION
                                    </p>
                                }                                
                                { (myTutorDetail &&myTutorDetail.reviews.length > 0) && 
                                    <p className={`head-menu ${menu === "REVIEWS" ? 'active' : ''}`} onClick={e => this.setState({menu: "REVIEWS"})}>
                                        REVIEWS
                                    </p>
                                }
                                { (myTutorDetail &&myTutorDetail.blogs.length > 0) && 
                                    <p className={`head-menu ${menu === "BLOG" ? 'active' : ''}`} onClick={e => this.setState({menu: "BLOG"})}>
                                        BLOG
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="instructor-profile show-mobile-flex mobile">
                            {this.renderInstructorInfo()}
                        </div>
                        <div className={`detail-info v-r ${(menu === 'BLOG' || menu === 'REVIEWS') ? 'no-margin' : ''}`}>
                            
                            {menu === 'ABOUT ME' && <div id="section-skills" className="v-r">
                                <div className="option-group v-r">
                                    <h2 className="option-title">Fun facts</h2>
                                    <p>{selectedInstructor.fun_facts}</p>
                                </div>
                                <div className="option-group v-r">
                                    <h2 className="option-title">About me</h2>
                                    <p>{selectedInstructor.about_me}</p>
                                </div>
                                <div className="option-group v-r">
                                    <h2 className="option-title">Instructing experiences</h2>
                                    <p>{selectedInstructor.experience}</p>
                                </div>
                                <div className="option-group v-r">
                                    <h2 className="option-title">Instructing approach</h2>
                                    <p>{selectedInstructor.approach}</p>
                                </div>
                            </div>}
							{menu === 'MY SKILLS' && <div id="section-skills" className="v-r">                                
                                <div className="option-group v-r">
                                    <h2 className="option-title">Courses</h2>
									<div className="skill-set">
										{myTutorDetail.skills.map((item, index) => <div className="subject" key={index}>{item}</div>)}
									</div>
                                </div>
							</div>}
                            {menu === 'QUALIFICATION' && <div id="section-skills" className="v-r">
                                {myTutorDetail.qualifications.map((item, index) => <div className="option-group v-r" key={index}>
                                    <h2 className="option-title">{item.subject}</h2>
                                    <p>{item.content}</p>
                                </div>)}
                            </div>}
                            {menu === 'REVIEWS' && <div id="section-skills" className="v-r">
                                {!_.isEmpty(myTutorDetail.reviews) && myTutorDetail.reviews.map((item, index) => { return (index < show_review && <ClientFeedback key={index} item={item}/>)})}
                                {(!_.isEmpty(myTutorDetail.reviews) && myTutorDetail.reviews.length - show_review > 0) && <button className="btn-2 btn-2-outline load-more-btn" onClick={e => this.setState({show_review: show_review + 3})}>Load More</button>}
                            </div>}
                            {menu === 'BLOG' && <div id="section-skills" className="v-r">
                                {myTutorDetail.blogs.map((item, index) => { return (index < show_blog && <div className="blog" key={index}>
                                    <img src={item.picture} alt="blog" />
                                    <div className="blog-detail v-r">
                                        <h2>{item.title}</h2>
                                        <div className="article-content" dangerouslySetInnerHTML={innerFunc(item.excerpt)} />
                                        <div className="read-more">Read More</div>
                                    </div>
                                </div>)})}
                                {(myTutorDetail.blogs.length - show_blog > 0) && <button className="btn-2 btn-2-outline load-more-btn" onClick={e => this.setState({show_blog: show_blog + 4})}>Load More</button>}
                            </div>}
                        </div>
                    </div>}
                </div>
                <ConfirmModal
                    isOpenModal={this.state.isOpenModal}
                    deleteConfirm={(e) => this.deleteConfirm(e)}
                    cancelTitle = "Delete this tutor?"
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        instructor_list: state.Instructor.instructor_list,
        instructor_list_loading: state.Instructor.instructor_list_loading,
        delete_tutor_loading: state.Instructor.delete_tutor_loading,
        deleteTutorStatus: state.Instructor.deleteTutorStatus,
        deleteTutorErr: state.Instructor.deleteTutorErr,


		myTutorDetail: state.Instructor.myTutorDetail,
		course_list: state.Lesson.course_list
    }
}

export default connect(mapStateToProps, { 
    getAllInstructors, 
    getMytutorDetail,
    deleteTutor,
    getAllCourses,
})(withRouter(MyInstructorsPage));