import { 
	UPDATE_PLAN, 
	UPDATE_PLAN_SUCCESS, 
	UPDATE_PLAN_FAIL, 
	AUTH_UPDATE_PLAN, 
	GET_PLAN, GET_PLAN_FAIL, 
	GET_PLAN_SUCCESS, 
	UNSUBSCRIBE_PLAN, 
	AUTH_DEACTIVATE_PLAN 
} from '../Type';
import _ from 'lodash'

import { takeLatest, put } from 'redux-saga/effects';
import {
	updatePlan as updatePlanApi,
	getPlan as getPlanApi,
	unsubscribePlan as unsubscribePlanApi,
} from '../../Api';

function* updatePlan(payload){
	try {
		const result = yield updatePlanApi(payload.data);
		if (result.message && result.message.length > 0) {
			yield put({ type: UPDATE_PLAN_FAIL, data: {msg: result.message}})
		} else {
			yield put({ type: UPDATE_PLAN_SUCCESS, data: result})
			yield put({ type: AUTH_UPDATE_PLAN, data: { plan_id: result.data.Plan.id, pay_per: result.data.pay_per, status: result.data.status }})
		}
	} catch (err) {
		yield put({ type: UPDATE_PLAN_FAIL, data: {msg: 'NET ERROR'} })
	}
}

export function* watchUpdatePlan(){
	yield takeLatest(UPDATE_PLAN, updatePlan)
}

function* unsubscribePlan(payload){
	try {
		const result = yield unsubscribePlanApi(payload.data.plan_id);
		if (result.message && result.message.length > 0) {
			yield put({ type: UPDATE_PLAN_SUCCESS, data: result})
			yield put({ type: AUTH_DEACTIVATE_PLAN, data: { status: 'inactive' }})
		} else {
			yield put({ type: UPDATE_PLAN_FAIL, data: {msg: 'NET ERROR'}})
		}
	} catch (err) {
		yield put({ type: UPDATE_PLAN_FAIL, data: {msg: 'NET ERROR'} })
	}
}

export function* watchUnsubscribePlan(){
	yield takeLatest(UNSUBSCRIBE_PLAN, unsubscribePlan)
}

function* getPlan(){
	try {
		const result = yield getPlanApi();
		if (!_.isEmpty(result.data)) {
			yield put({ type: GET_PLAN_SUCCESS, data: result.data})
		} else {
			yield put({ type: GET_PLAN_FAIL, data: result.data})
		}
	} catch (err) {
		yield put({ type: GET_PLAN_FAIL, data: {msg: 'NET ERROR'} })
	}
}

export function* watchGetPlan(){
	yield takeLatest(GET_PLAN, getPlan)
}
