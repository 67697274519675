import React from 'react';
import './ClientFeedback.scss';
import StarRatings from 'react-star-ratings';
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';
import moment from 'moment';

export default class ClientFeedback extends React.Component {
    render() {
        const { item, className } = this.props;
        return (
            <div className={`clientfeedback-component ${className || ''}`}>
                <img className="initial" src={item.student.avatar} alt="feedback-avatar"/>
                <div className="user-info">
                    <div className="basic"> 
                        <div><span className="name">{item.student.name}</span> from {item.student.location}</div>
                        <div>{moment(item.reviewed_on).format("MMMM DD, YYYY")}</div>
                    </div>
                    <div className="sub-info">
                        {/* <div className="subject">Knowledge: </div> */}
                        <StarRatings
                            rating={item.rating}
                            starDimension="16px"
                            starSpacing="2.14px"
                            starRatedColor="#23A4EF"
                            starEmptyColor="#D3EDFC"
                            svgIconViewBox={StarSVGViewBox}
                            svgIconPath={StarSVGIconPath}
                        />
                    </div>
                    <div className="description">{item.review_content}</div>
                </div>
            </div>
        );
    }
}
