import { Api } from './Api';

export function* getAllInstructors() {
    const jsonData = yield Api.get("/connections/mytutors");
    return jsonData;
}

export function* getMytutorDetail(tutor_id) {
    const jsonData = yield Api.get(`/connections/mytutordetail?tutor_id=${tutor_id}`);
    return jsonData;
}

export function* deleteTutor(tutor_id) {
    const jsonData = yield Api.delete(`/connections/${tutor_id}`);
    return jsonData;
};

export function* requestInstructor(data) {
    const jsonData = yield Api.post("/requests", data);
    return jsonData;
}

export function* getSchools() {
    const jsonData = yield Api.get("/schools");
    return jsonData;
}