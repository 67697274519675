import React from 'react';
import './ReferralPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getRecommendation,
} from "../../Redux/Actions";


class ReferralPage extends React.Component {
    state = {
        tutors: [],
        recommendations: [],
    }

    componentDidMount() {
        this.props.getRecommendation();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.recommendation?.tutors?.length !== this.props.recommendation?.tutors?.length){
            this.setState({
                tutors: this.props.recommendation.tutors,
                recommendations: this.props.recommendation.recommendations,
            });
        }
    }

    render() {
        const { tutors, recommendations } = this.state;
        return (
            <div className="referral-page v-r">
                <div className="settings-body">
                    <div className="container settings-container">
                        <div id="section-profile">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="option-group">
                                        <h3>Refer Your Friends: Get FREE $200 & Give FREE Trial Class</h3>
                                    </div>
                                    <div className="option-group">
                                        Let your friends, family, neighbors, and the community knows through social what a fantastic job your tutors are doing. If they click on the link and sign up for a coding lessons, you get $200 in free tutoring credit, and they get a free trial class for using your link. There is no limit on how much tutoring credit you can earn.
                                    </div>
                                    <div className="option-group">
                                        <h3>Please go here to refer each of your tutor on social media:</h3>
                                        <ul>
                                        {tutors.map((item, index) => <li key={index} tabIndex={index}>
                                                    <a href={item.link}>{item.name}</a>
                                                </li>)}
                                        </ul>
                                    </div>
                                    <div className="option-group">
                                        <h3>Friends who have signed up from your link</h3>
                                        <div className="data-area v-r">
                                            <div className="data-header show-web-flex v-c">
                                                <div className="cell cell-1">USER</div>
                                                <div className="cell cell-2">SIGNUP DATE</div>
                                                <div className="cell cell-3">STATUS</div>
                                            </div>
                                            <div className="data-list show-web-flex v-r">
                                                {recommendations.map((item, index) => <div className='data-row' key={index} tabIndex={index}>
                                                    <div className="cell cell-1">{item.user}</div>
                                                    <div className="cell cell-2">{item.signup_date}</div>
                                                    <div className="cell cell-3">{item.status}</div>
                                                </div>)}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>                            
                            
                        </div>
                        
                        
                        
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        recommendation: state.Authentication.recommendation,
    }
}

export default connect(mapStateToProps, {
    getRecommendation,
})(withRouter(ReferralPage));
