import {
    GET_INSTRUCTOR_LIST, 
    GET_INSTRUCTOR_LIST_SUCCESS, 
    GET_INSTRUCTOR_LIST_FAIL, 
    GET_MY_TUTOR_DETAIL, 
    GET_MY_TUTOR_DETAIL_SUCCESS, 
    GET_MY_TUTOR_DETAIL_FAIL, 
    GET_DELETE_TUTOR, 
    GET_DELETE_TUTOR_SUCCESS,
    GET_DELETE_TUTOR_FAIL, 
    REQUEST_INSTRUCTOR, 
    REQUEST_INSTRUCTOR_SUCCESS, 
    REQUEST_INSTRUCTOR_FAIL, 
    GET_SCHOOL_LIST, 
    GET_SCHOOL_LIST_SUCCESS, 
    GET_SCHOOL_LIST_FAIL,
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';
import {
    getAllInstructors as getAllInstructorsApi,
    getMytutorDetail as getMytutorDetailApi,
    deleteTutor as deleteTutorApi,
    requestInstructor as requestInstructorApi,
    getSchools as getSchoolsApi,
} from '../../Api';

/**
 * Get All Tutors
 */
function* getAllInstructors(payload){
    try {
        const result = yield getAllInstructorsApi();
        if (result.data && result.data.length > 0) {
            yield put({ type: GET_INSTRUCTOR_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_INSTRUCTOR_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_INSTRUCTOR_LIST_FAIL, data: [] })
    }
}

export function* watchGetAllInstructors(){
    yield takeLatest(GET_INSTRUCTOR_LIST, getAllInstructors)
}

/**
 * Get Tutor Detail
 */
function* getMytutorDetail(payload){
    try {
        const result = yield getMytutorDetailApi(payload.data.tutor_id);
        if (result.data) {
            yield put({ type: GET_MY_TUTOR_DETAIL_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_MY_TUTOR_DETAIL_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_MY_TUTOR_DETAIL_FAIL, data: [] })
    }
}

export function* watchgetMytutorDetail(){
    yield takeLatest(GET_MY_TUTOR_DETAIL, getMytutorDetail)
}

/**
 * Delete Tutor
 */
function* deleteTutor(payload){
    try {
        const result = yield deleteTutorApi(payload.data.tutor_id);
        if (result.is_success) {
            yield put({ type: GET_DELETE_TUTOR_SUCCESS, data: result.message })
        } else {
            yield put({ type: GET_DELETE_TUTOR_FAIL, data: result.message })
        }
    } catch (err) {
        yield put({ type: GET_DELETE_TUTOR_FAIL, data: [] })
    }
}

export function* watchdeleteTutor(){
    yield takeLatest(GET_DELETE_TUTOR, deleteTutor)
}

function* requestInstructor(payload){
    try {
        const result = yield requestInstructorApi(payload.data);
        if (result) {
            yield put({ type: REQUEST_INSTRUCTOR_SUCCESS, data: result })
        } else {
            yield put({ type: REQUEST_INSTRUCTOR_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: REQUEST_INSTRUCTOR_FAIL, data: [] })
    }
}

export function* watchRequestInstructor(){
    yield takeLatest(REQUEST_INSTRUCTOR, requestInstructor)
}








function* getSchools(payload){
    try {
        const result = yield getSchoolsApi();
        if (result.data) {
            yield put({ type: GET_SCHOOL_LIST_SUCCESS, data: result.data })
        } else {
            yield put({ type: GET_SCHOOL_LIST_FAIL, data: [] })
        }
    } catch (err) {
        yield put({ type: GET_SCHOOL_LIST_FAIL, data: [] })
    }
}

export function* watchGetSchools(){
    yield takeLatest(GET_SCHOOL_LIST, getSchools)
}