import { Api } from './Api';

export function* getReviews(){
    const jsonData = yield Api.get("/connections/myreviews");
    return jsonData;
}

export function* leaveReview(data){
    const jsonData = yield Api.post(`/connections/${data.tutor_id}`, data);
    return jsonData;
}