import { AUTH_UPDATE_TOKEN, GET_MY_INFO, UPDATE_MY_INFO, UPDATE_PASSWORD, AUTH_SIGN_OUT, GET_RECOMMENDATION,
 } from '../Type';

export const updateToken = (token) => {
  return {
    type: AUTH_UPDATE_TOKEN,
    data: { token }
  }
}

export const getMyInfo = () => {
  return {
    type: GET_MY_INFO,
    data: {}
  }
}

export const updateMyInfo = (data) => {
  return {
    type: UPDATE_MY_INFO,
    data: data,
  }
}

export const signOut = () => {
	return {
		type: AUTH_SIGN_OUT,
		data: {}
	}
}

export const getRecommendation = () => {
  return {
    type: GET_RECOMMENDATION,
    data: {}
  }
}
