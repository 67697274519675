import React from 'react';
import './App.scss';
import { Sidebar, MobileSidebar, VideoModal, JoinLesson, CreditModal, ReviewModal, AuthCheck } from './Components';
import { 
  MyInstructorsPage, RatePlanPage, ReviewInstructorsPage, CreditCardPage, ReceiptPage, ScheduleLessonPage, RequestInstructorPage,
  SettingsPage, MessagePage, CompletedLessonPage, PendingLessonPage, ReferralPage
} from './Pages';
import Page from './Pages/Page';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader'
import { EventEmitter } from './Utils/events';
import { css } from "@emotion/core";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { toggleReviewModal } from './Redux/Actions';

const Pages = [
  { url: "/", title: 'My Instructors', page: props => <MyInstructorsPage {...props} /> },
  { url: "/instructors/:id", title: 'My Instructors', page: props => <MyInstructorsPage {...props} /> },
  { url: "/instructors", title: 'My Instructors', page: props => <MyInstructorsPage {...props} /> },
  { url: "/rate-plan", title: 'Rate Plan', page: props => <RatePlanPage {...props} /> },
  { url: '/review-instructors', title: 'Review Instructors', page: props => <ReviewInstructorsPage {...props} /> },
  { url: '/credit-card', title: 'Credit Card', page: props => <CreditCardPage {...props} /> },
  { url: '/receipt/:id', title: 'Receipt', page: props => <ReceiptPage {...props} /> },
  { url: '/receipt', title: 'Receipt', page: props => <ReceiptPage {...props} /> },
  { url: '/schedule-lesson/:instructor_id', title: 'Schedule a lesson', page: props => <ScheduleLessonPage {...props} /> },
  { url: '/schedule-lesson', title: 'Schedule a lesson', page: props => <ScheduleLessonPage {...props} /> },
  { url: '/request-instructor', title: 'Request new instructor', page: props => <RequestInstructorPage {...props} /> },
  { url: '/profile', title: 'My Settings', page: props => <SettingsPage {...props} /> },
  { url: '/message', title: 'Message', page: props => <MessagePage {...props} /> },
  { url: '/completed-lesson/:id', title: 'Completed Lessons', page: props => <CompletedLessonPage {...props} /> },
  { url: '/completed-lesson', title: 'Completed Lessons', page: props => <CompletedLessonPage {...props} /> },
  { url: '/pending-lesson/:id', title: 'Pending Lessons', page: props => <PendingLessonPage {...props} /> },
  { url: '/pending-lesson', title: 'Pending Lessons', page: props => <PendingLessonPage {...props} /> },
  { url: '/referral', title: 'Referral', page: props => <ReferralPage {...props} />},
];

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class App extends React.Component {
  state = {
    isBlur: false,
    isSidebarOpen: true,
    isSidebarPrevOpen: true,
    isResize: false,
    loading: false
  }

  componentDidMount(){
    const { user } = this.props;
    if(user.connection_need_review){
      this.props.toggleReviewModal(true, {
        connection_id: user.connection_need_review.id, 
        picture: user.connection_need_review.tutor.picture,
        first_name:  user.connection_need_review.tutor.name? user.connection_need_review.tutor.name.split(' ')[0] : '',
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    return {
      isBlur: props.isMobileSidebarOpen || props.isModalOpen,
      isSidebarOpen: props.isSidebarOpen,
      isSidebarPrevOpen: state.isSidebarOpen,
      isResize: props.isJoinLessonOpen
    }
  }

  render() {
    const { isBlur, loading, isSidebarOpen, isSidebarPrevOpen, isResize } = this.state;
    EventEmitter.subscribe('isLoading', (event) => this.setState({loading: event}));

    if (isBlur) {
      if (document.body) {
        const body = document.body
        body.classList.add('modal-open');
      }
    } else {
      if (document.body) {
        const body = document.body
        body.classList.remove('modal-open');
      }
    }
    
    return (
      <BrowserRouter>
        <div className="App">
          <JoinLesson />
          <div id="main" className={`${isBlur? 'blur': ''} ${isResize ? 'resize' : ''}`}>
            <MobileSidebar />
            <div className={`content-wrapper ${isSidebarOpen ? isSidebarPrevOpen ? '' : 'open' : 'closed'}`}>
              <Switch>
                <Route exact path="/auth" component={AuthCheck} />
                {Pages.map((item, index) => 
                  <Route exact path={item.url} key={index}
                    render={() => this.props.isLoggedIn === true
                      ?  <Page title={item.title} page={item.page} />
                      : <Redirect to="/auth" />}
                  />
                )}
              </Switch>
            </div>
            <Sidebar />
            <VideoModal />
            <CreditModal />
            <ReviewModal />
          </div>          
        </div>
        <div className={loading ? "overlay-loader" : "d-none"}>
          <BeatLoader
            css={override}
            size={30}
            color={"#FFF"}
            loading={loading}
          />
        </div>
        <NotificationContainer />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobileSidebarOpen: state.Sidebar.isMobileOpen,
    isSidebarOpen: state.Sidebar.isOpen,
    isModalOpen: state.Modal.isVideoModalOpen || state.Modal.isCreditModalOpen || state.Modal.isReviewModalOpen,
    isJoinLessonOpen: state.Lesson.isOpen,
    isLoggedIn: state.Authentication.isLoggedIn,
    user: state.Authentication.user
  }
}

export default connect(mapStateToProps, {toggleReviewModal})(App);
