import React from 'react';
import './RatePlanPage.scss';
import { Toggle, CardInputBox, InputBox } from '../../Components';
import { ReactSVG } from 'react-svg';
import card from '../../Assets/Icon/credit-card.svg';
import left from '../../Assets/Icon/arrow-left.svg';
import confirm from '../../Assets/Icon/confirm.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updatePlan, unsubscribePlan, getBraintreekey, getPlan, getCardInfo, updateCard, updateMyInfo } from '../../Redux/Actions';
import { braintainService} from '../../services/paymentServices';

import _ from 'lodash'
import Helper from '../../Utils/Helper';

const PeriodList = [
    { name: 'Monthly', length: 1 },
    { name: '3 Months', length: 3 },
    { name: '6 Months', length: 6 },
    { name: 'Yearly', length: 12 }
];

class RatePlanPage extends React.Component {
    state = {
        selected_plan: 0,

        active_period: 1,
        update_btn: 0,

        depth: 0,
        error: '',				
        coupon: '',
        braintreeKey: '',

        card_update: false,
        old_cardInfo: null,

        card_number: '',
        month: '',
        year: '',
        cvv2: '',
        zip_code: '',

        check_sign: false,

        is_make_payment: false

    }

    componentDidMount() {
        this.setState({
            depth: this.props.depth
        });
        if(!this.props.braintreeKey){
            this.props.getBraintreekey();
        };
        if(_.isEmpty(this.props.plans)){
            Helper.showSpinner();
            this.props.getPlan();
        };
        this.props.getCardInfo();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.update_success && this.props.update_success) {
            // alert("Successfully Enrolled!");
            this.props.formatTitle();
            return;
        }
        if (prevProps.update_error_msg.length === 0 && this.props.update_error_msg.length > 0) {
            alert(this.props.update_error_msg);
            return;
        }
        if (prevProps.get_plans_loading && !this.props.get_plans_loading) {
            Helper.hideSpinner();
        }

        if (prevProps.update_card_loading && !this.props.update_card_loading) {
            Helper.hideSpinner();
            const { update_card_success, update_card_message } = this.props;
            if(update_card_success){
                Helper.showToast('success', 3000, update_card_message);
                this.props.getCardInfo();
                if(this.state.is_make_payment){
                    this.profileUpdate();
                }
            } else {
                Helper.showToast('error', 3000, update_card_message);
            };
        };

        if (prevProps.update_info_loading && !this.props.update_info_loading) {
            Helper.hideSpinner();

			const {update_info_success, profileUpdateErr} = this.props;
			if(update_info_success){
				Helper.showToast('success', 3000, 'Successfully updating!');
				this.setState({
					last_password: '',
					new_password: ''
				});
			} else {
				Helper.showToast('error', 3000, profileUpdateErr);
			}
		};
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth
        }
    }

    overButton = (num) => {
        this.setState({ activeColumn: num })
    }

    leaveButton = (num) => {
        if (num === this.state.activeColumn) {
            this.setState({activeColumn: 0})
        }
    }

    updateValue = (field, value) => {
        this.setState({[field]: value});
    }

    goBack = () => {
        this.props.updateTitle('pop');
    }

    navigateToEnroll = (plan_id) => {
        this.setState({selected_plan: plan_id})
        this.props.updateTitle('push', 'Make Payment');
    }

    editCard = (item) => {
        this.setState({card_update: true});
        this.setState({old_cardInfo: item});
    };

    addNewCard = () => {
        this.setState({card_update: true});
        this.setState({old_cardInfo: null});
    };

    removeCard = (item) => {
        Helper.showSpinner();

        const formData = new FormData();       
        formData.append('todo', 'remove');
        formData.append('payment_method_token', item.payment_method_token);

        this.props.updateCard(formData);

        this.resetCard();
    };

    setAsDefaultCard = (item) => {
        Helper.showSpinner()
        const formData = new FormData();       
        formData.append('todo', 'set_default');
        formData.append('payment_method_token', item.payment_method_token);

        this.props.updateCard(formData);

        this.resetCard();
    };

    makePayment = () => {
        Helper.showSpinner() ;
        this.setState({is_make_payment: true});
        const { card_number, month, year, cvv2, card_update, zip_code } = this.state;
        if(card_update) {
            const { braintreeKey } = this.props;
            var cardDetail = {
                card_number,
                month,
                year,
                cvv2,
                zip_code
            };
            braintainService(braintreeKey, cardDetail).then((res)=>{
                this.sendNonce(res);
            })
            .catch(err => {
                Helper.showToast('error', 3000, "Invalid Card Info");
            });
        } else {
            this.profileUpdate();
        };
        
    };

    sendNonce = (nonce) => {        
        const { old_cardInfo } = this.state;
        const formData = new FormData()
        formData.append('nonce', nonce);

        if(old_cardInfo){
            formData.append('todo', 'update');
            formData.append('payment_method_token', old_cardInfo.payment_method_token);
        } else {
            formData.append('todo', 'new');
        };
    
        this.props.updateCard(formData);
    };

    profileUpdate = () => {
        const { selected_plan, coupon } = this.state;
        if(selected_plan == 0)return;
        const formData = new FormData()
        formData.append('plan_id', selected_plan );
        formData.append('coupon', coupon );

        this.props.updateMyInfo(formData);
        this.setState({is_make_payment: false});
        this.resetCard();
    };

    resetCard = () => {
        this.setState({
            is_make_payment: false,
            card_number: '',
            month: '',
            year: '',
            cvv2: '',
        });
    };

    getOffPercent = (plan_duration) => {
        const { plans } = this.props;
        const active_plans = plans.filter(plan => plan.length == plan_duration);
        if(active_plans.length > 0){
            const discount_amount = active_plans[0].discount_amount
            const original_amount = active_plans[0].original_amount
            const offPercent = (1 - discount_amount/original_amount) *100
            if(offPercent > 0) {
                return (
                    `(${Math.round(offPercent)} % Off)`
                )
            }
        }
        return ''
    };

    render() {
        const { active_period, depth, error, update_btn, coupon, old_cardInfo, card_update, card_number, month, year, cvv2, zip_code, check_sign } = this.state;       
        const { plans, user, card_numbers } = this.props;
        const current_plan_list = plans.filter(plan => plan.length == active_period);
        return (
            <>
                {!_.isEmpty(plans) && (<div className="rateplan-page v-r">
                    {depth === 0 && <div className="container plan-area v-r v-c">
                        <h1>{ user.plan_status == 'active' ? 'Change Your Rate Plan' : 'Your Subscription is inactive. Select Your Rate Plan'}</h1>
                        {/* <div className="toggle-area v-c">
                            <p className="left-option v-c">One lesson&nbsp;<span className="show-web">per week</span> <span className="show-mobile">pw</span></p>
                            <Toggle className="option-toggle" value={option} onChange={val => this.toggleLesson(val)} />
                            <p className="v-c">Two lessons&nbsp;<span className="show-web">per week</span> <span className="show-mobile">pw</span></p>
                        </div> */}
                        <div className="period-row v-c">
                            {PeriodList.map((item, index) => <div className={`period v-c h-c ${active_period === item.length ? 'active' : ''}`} key={index} onClick={e=>this.setState({active_period: item.length})}>
                                {`${item.name} ${this.getOffPercent(item.length)}`}
                            </div>)}
                        </div>
                        <div className="period-row coupon-row v-c v-r">
                            <p>Please input coupon code if you have any</p>
                            <input className="coupon-input" value={coupon} onChange={e => this.setState({coupon: e.target.value})} />
                        </div>
                        <div className="pricing-body">
                            {current_plan_list.length == 0 ? <div>No Plan Available</div> : current_plan_list.map(
                                plan => <div className="coding-class round-object private v-r" key={plan.id}>
                                <div className="sub-header v-r v-c">
                                    <h2>{plan.name}</h2>
                                    <h3>
                                        {plan.original_amount !== plan.discount_amount && <span className="ignore">{plan.original_amount}</span>}
                                        <span>${plan.discount_amount}<span className="unit">/{plan.pay_per}</span></span>
                                    </h3>
                                </div>
                                <div className="sub-body v-r">
                                    {plan.description.map((item, index) => <div className="sub-item v-c" key={index}>
                                        <ReactSVG src={confirm} className="svg-icon" />
                                        <p> {item} </p>
                                    </div>)}
                                    <button className={`btn-2 btn-link ${ user.plan_id == plan.id && user.plan_status == 'active' ? 'disabled' : ''}`} onClick={e => this.navigateToEnroll(plan.id)}>
                                        { user.plan_id == plan.id && user.plan_status == 'active' ? 'CURRENT PLAN' :'ENROLL NOW'}
                                    </button>
                                </div>
                            </div>
                            )}
                            
                            
                        </div>
                    </div>}
                    {depth === 1 && <div className="container payment-area">
                        <h1 className="show-web">Make Payment</h1>
                        <ReactSVG src={left} className="svg-icon back-icon show-web" onClick={e => this.goBack()} />
                        <div className="main-content">
                            <h5>{!_.isEmpty(card_numbers)? 'Card Information: ' : ''}</h5>
                            {!_.isEmpty(card_numbers) && card_numbers.map((data, index)=> (
                                <div className="edit-card" key={index}>
                                    <div className="card-num-date">
                                        <p className="card-brand">{data.masked_card_number}</p>
                                        <p className="ml-5">{`Expires: ${data.exp_month}/${data.exp_year}`}</p>
                                    </div>
                                    <div className="edit-card-row">
                                        <div className="edit-item-btn" onClick={e => this.editCard(data)}>Edit</div>            
                                        <div className="edit-item-btn" onClick={e => this.removeCard(data, index)}>Remove</div>            
                                        {!data.is_default && <div className="edit-item-btn" onClick={e => this.setAsDefaultCard(data, index)}>Set as default</div>}
                                    </div>                                
                                </div>
                            ))}
                            <div className="add-new-card" onClick={e => this.addNewCard()}>Add New Card</div>
                            
                            {card_update &&
                                <div className="mt-5">
                                    <div className="row">
                                        <div className="col-lg-12 form-group">
                                            <label>{`Card Number(${old_cardInfo? 'Edit' : 'New'})`}</label>
                                            <CardInputBox
                                                icon={card}
                                                type="number"
                                                value={card_number}
                                                onChange={val => this.updateValue('card_number', val)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 form-group">
                                            <label>Month</label>
                                            <InputBox value={month} placeholder="Month" onChange={val => this.updateValue('month', val)}/>
                                        </div>
                                        <div className="col-lg-3 form-group">
                                            <label>Year</label>
                                            <InputBox value={year} placeholder="Year" onChange={val => this.updateValue('year', val)}/>
                                        </div>
                                        <div className="col-lg-3 form-group">
                                            <label>CVV2</label>
                                            <InputBox value={cvv2} placeholder="CVV2" onChange={val => this.updateValue('cvv2', val)}/>
                                        </div>
                                        <div className="col-lg-3 form-group">
                                            <label>Zipcode</label>
                                            <InputBox value={zip_code} placeholder="Zipcode" onChange={val => this.updateValue('zip_code', val)}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                {check_sign && card_numbers && month && year && cvv2 || !card_update && check_sign && !_.isEmpty(card_numbers)?
                                    <div className="col-lg-12 form-group">
                                        <button className="btn-2" onClick={e => this.makePayment()}>Make Payment</button>
                                    </div>
                                    :
                                    <div className="col-lg-12 form-group">
                                        <button className="btn-2 disabled" >Make Payment</button>
                                    </div>
                                }
                            </div>

                            <div className="row">
                                <div className="col-lg-12 form-group toggle-area">
                                    <Toggle value={check_sign} className="custom-toggle" isPolicy={true} onChange={val => this.updateValue('check_sign', val)} />
                                    <p>On the Icode platform, your subscription includes a set number of tutoring minutes for a specified period of time. The tutoring minutes do not roll over from one period to another, and we do not issue refunds without reason. Cancel at any time. You are billed either weekly, biweekly, monthly, or one time depending on your plan. Additional tutoring sessions not covered by your plan will be billed individually. Your membership will be renewed automatically at the beginning of each cycle until you cancel. Tutor satisfaction guaranteed: If you do not like your tutor that you are assigned, we assign you a new tutor at no additional cost.</p>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>)}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
		user: state.Authentication.user,

        plans: state.Plan.plans,
        get_plans_loading: state.Plan.get_plans_loading,    

        update_success: state.Plan.update_success,
        update_error_msg: state.Plan.update_error_msg,
        update_loading: state.Plan.update_loading,

        braintreeKey: state.Payment.braintreeKey,
        get_braintreeKey_loading: state.Payment.get_braintreeKey_loading,

        card_numbers: state.Payment.card_numbers,
        update_card_loading: state.Payment.update_card_loading,
        update_card_success: state.Payment.update_card_success,
        update_card_message: state.Payment.update_card_message,

        update_info_loading: state.Authentication.update_info_loading,
		update_info_success: state.Authentication.update_info_success,
		profileUpdateErr: state.Authentication.profileUpdateErr,
    }
}

export default connect(mapStateToProps, { 
    updatePlan,
    unsubscribePlan,
    getBraintreekey,
    getPlan,
    getCardInfo,
    updateCard,
    updateMyInfo
})(withRouter(RatePlanPage));