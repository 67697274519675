import React from 'react';
import './PendingLessonPage.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { InputBox, RoundButton, SelectBox, ConfirmModal } from '../../Components';
import search from '../../Assets/Icon/search.svg';
import left from '../../Assets/Icon/left.svg';
import right from '../../Assets/Icon/right.svg';
import play_button from '../../Assets/Icon/play-button.svg';
import Helper from '../../Utils/Helper';

import moment from 'moment';
import { toggleVideoModal, toggleJoinLesson, getPendingLesson, cancelLesson } from '../../Redux/Actions';

class PendingLessonPage extends React.Component {
    timer = null
    
    state = {
        id: '',
        data: [],
        filteredList: [],
        
        searchText: '',
        currentPage: 1,
        totalPage: 1,
        displayCount: 10,

        depth: 0,
        selectedLesson: {},

        selected_id: 0,
        incomingLessonInfo: {},

        seconds: 10,
        cancel_id: 0,
        isOpenModal: false,
        current_timezone: moment.tz.guess(true)
    }

    static getDerivedStateFromProps(props, state) {
        const id = (props.match.params.id && props.match.params.id.length > 0) ? props.match.params.id : '';
        return {
            id: id,
            depth: props.depth,
            incomingLessonInfo: props.incomingLessonInfo,
            data: props.pending_lesson_list
        };
    }

    componentDidMount() {
        const { current_timezone } = this.state;
        const timezone = moment.tz(moment.utc(), current_timezone).utcOffset()/60;
        
        this.props.pending_lesson_list.length === 0 ? this.props.getPendingLesson(timezone) : this.filterData('', 10);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    componentDidUpdate(prevProps, prevState) {
        const prevID = (prevProps.match.params.id && prevProps.match.params.id.length > 0) ? prevProps.match.params.id : '';
        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
        if (prevID === newID && prevID !== '' && newID !== '') {
            if (this.state.depth !== 1 && prevState.depth === 0) {
                let index = this.state.data.findIndex(item => item.id === parseInt(newID, 10));
                let lesson = this.state.data[index];
                this.setState({selectedLesson: lesson, seconds: 10});
                // if (!lesson.cancelled && !lesson.actions !== 'error') {
                //     clearInterval(this.timer);
                //     this.timer = setInterval(() => {
                //         let { seconds } = this.state;
                //         if (this.state.depth === 1) {
                //             if (seconds > 0) {
                //                 this.setState({ seconds: seconds - 1 })
                //             } else {
                //                 clearInterval(this.timer);
                //                 this.timer = null;
                //             }
                //         } else {
                //             clearInterval(this.timer);
                //             this.timer = null;
                //         }
                        
                //     }, 1000);
                // }
                this.props.updateTitle('push', 'Pending Lessons', 2);
            }
        }
        if (prevProps.pending_lesson_list.length !== this.props.pending_lesson_list.length) {
            this.filterData(this.state.searchText, this.state.displayCount);
        }

        if (prevProps.cancel_lesson_loading && !this.props.cancel_lesson_loading) {
            if(this.props.cancel_lesson_success){
                Helper.showToast('success', 3000, this.props.cancel_lesson_message)
                this.props.getPendingLesson();
            } else {
                Helper.showToast('error', 3000, this.props.cancel_lesson_message)
            }
        }
    }

    changeDisplayCount = (val) => {
        const { searchText } = this.state;
        this.filterData(searchText, val);
    }

    changeSearchText = (val) => {
        const { displayCount } = this.state;
        this.filterData(val, displayCount);
    }

    changeCurrentPage = (direction) => {
        const { currentPage, totalPage } = this.state;
        if ((direction === 'previous' && currentPage === 1) || (direction === 'next' && currentPage === totalPage)) return;
        this.setState({
            currentPage: direction === 'next' ? currentPage + 1 : currentPage - 1
        })
    }

    filterData = (searchText, displayCount) => {
        const { data } = this.state;
        const filteredList = data.filter(item => item.tutor.name.indexOf(searchText) > -1);
        const totalPage = Math.ceil(filteredList.length / displayCount);

        this.setState({
            searchText: searchText,
            filteredList: filteredList,
            totalPage: totalPage,
            displayCount: displayCount,
            currentPage: 1
        })
    }

    viewItem = (e, item) => {
        e.stopPropagation();
        this.props.history.push(`/pending-lesson/${item.id}`);
    }

    openVideoModal = (item) => {
        this.props.toggleVideoModal(true, item.actions);
    }

    cancelLesson = (item) => {
        // if (!window.confirm('Would you cancel the lesson?')) return;
        this.setState({cancel_id: item.id});
        this.setState({isOpenModal: true})
    }

    deleteConfirm =(deleteStatus, reason) => {
        this.setState({isOpenModal: false});
        if(deleteStatus){
            const formData = new FormData();
            formData.append('action', 'cancel');
            formData.append('reason', reason);
            formData.append('_method', 'PUT');
            this.props.cancelLesson(formData, this.state.cancel_id);
        };
    }

    selectLesson = (e, item) => {
        e.stopPropagation();
        // this.setState({selected_id: item.id});
        // if (!item.cancelled && !item.actions !== 'error' && !this.state.incomingLessonInfo.id) {
        //     this.props.toggleJoinLesson(true, {id: item.id});
        // }
    }

    navigateToInstructor = (e, instructor_id) => {
        e.stopPropagation();
        this.props.history.push(`/instructors/${instructor_id}`);
    }

    formatTime = (sec) => {
        let second = sec;
        const hour = Math.floor(second / 3600);
        second = second - hour * 3600;
        const minute = Math.floor(second / 60);
        second = second - minute * 60;
        return `${hour}:${minute < 10 ? '0'+minute : minute}:${second < 10 ? '0'+second : second}`;
    }

    joinLesson = () => {
        clearInterval(this.timer);
        this.timer = null;
        this.setState({seconds: 0});
	}
	
	checkLessonExpired = (lesson_date) => {
		let lesson = moment(lesson_date);
		return moment().diff(lesson) > 0;
    }
    
    seeRecording = (item) => {
        if(item.recorded_online_link){
            window.open(item.recorded_online_link);
        }
    }

    render() {
        const { searchText, displayCount, filteredList, currentPage, totalPage, depth, selected_id, cancel_id } = this.state;
        const startIndex = displayCount * (currentPage - 1);
        const displayList = filteredList.slice(startIndex, startIndex + displayCount);

        const newID = (this.props.match.params.id && this.props.match.params.id.length > 0) ? this.props.match.params.id : '';
        let selectedData = {};
        if (newID !== '') {
            let index = filteredList.findIndex(temp => temp.id === parseInt(newID, 10));
            selectedData = filteredList[index] || {};
        }
        
        return (
            <div className="pendinglesson-page v-r">
                {depth === 0 && <div className="options-area">
                    <InputBox className="search-input" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.changeSearchText(val)} />
                    <div className="count-area v-c">
                        <RoundButton icon={left} className="show-mobile-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <p className="page-count">Page {currentPage} of {totalPage  }</p>
                        <RoundButton icon={left} className="arrow-btn show-web-flex" onClick={e => this.changeCurrentPage('previous')}/>
                        <RoundButton icon={right} className="arrow-btn" onClick={e => this.changeCurrentPage('next')}/>
                        <div className="combo-area v-c">
                            <p>Items</p>
                            <SelectBox value={displayCount} onChange={val => this.changeDisplayCount(val.name)}/>
                        </div>
                    </div>
                </div>}
                {depth === 0 && <div className="data-area v-r">
                    <div className="data-header show-web-flex v-c">
                        <div className="cell cell-1">INSTRUCTOR</div>
                        <div className="cell cell-2">SCHEDULE</div>
                        <div className="cell cell-3">DURATION</div>
                        <div className="cell cell-5">CANCELLED</div>
                        <div className="cell cell-6">ACTIONS</div>
                    </div>
                    <div className="data-list show-web-flex v-r">
                        {displayList.map((item, index) => <div className={`data-row ${!this.checkLessonExpired(item.sched) && Math.floor((new Date(item.sched) - new Date()) / 1000) < 86400 ? 'selected' : ''}`} key={index} onClick={e => this.selectLesson(e, item)} onBlur={e=>this.setState({selected_id:0})} tabIndex={index}>
                            <div className="cell cell-1"><p style={{cursor:'pointer'}} onClick={e => this.navigateToInstructor(e, item.tutor.id)}>{item.tutor.name}</p></div>
                            <div className="cell cell-2"><span style={{cursor:'pointer'}} onClick={e => this.viewItem(e, item)}>{moment(item.sched).local().format('MMM D, YYYY hh:mm a')}</span></div>
                            <div className="cell cell-3">{item.hours}</div>
                            <div className="cell cell-5">{item.cancel_time ? item.cancel_time : 'NO'}</div>
                            <div className="cell ">
                                <div className={`v-c ${item.cancel_time ? '' : 'active'}`}>
                                    {/* {!item.cancel_time &&<p>{Math.floor((new Date() - new Date(item.sched)) / 1000) > 0 ? 'Error: No link to online session' : `Online tutoring starts in ${moment(item.sched).local().format('MMM D, YYYY hh:mm a')}`}</p>} */}
                                    {!item.cancel_time ? <React.Fragment>
                                        {this.checkLessonExpired(item.sched) ?
                                            <div className="v-c" onClick={e => this.seeRecording(item)}>
                                                <ReactSVG src={play_button} className="svg-icon"/> {item.recorded_online_link? "See Recording" : "No link to online session"}
                                            </div>
                                            :
                                            <p>{`Online tutoring starts in ${moment(item.sched).local().format('MMM D, YYYY hh:mm a')}`}</p>
                                        }
                                    </React.Fragment> : 'No link to online session'}
                                </div>
                            </div>
                            <div className="cell cell-6 v-c">
                                {!this.checkLessonExpired(item.sched) && Math.floor((new Date(item.sched) - new Date()) / 1000) < 1800 && <button className="btn-2 btn-2-outline cancel-btn outline-btn note-btn" onClick={e => this.joinLesson(item)}>Join</button>}
                                {(!item.cancel_time && !this.checkLessonExpired(item.sched)) && <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(item)}>Cancel</button>}
                            </div>
                        </div>)}
                    </div>
                    <div className="show-mobile-flex v-r">
                        {displayList.map((item, index) => <div className="mobile-row v-r" key={index}>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">INSTRUCTOR</div> <p className="active" onClick={e => this.navigateToInstructor(e, item.tutor.id)}>{item.tutor.name}</p> </div>
                                <div className="right-col v-r"> <div className="col-header">SCHEDULE</div> <p className="active" onClick={e => this.viewItem(e, item)}>{moment(item.sched).local().format('MMM D, YYYY hh:mm a')}</p> </div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">HOURS</div> <p>{item.hours}</p> </div>
                            </div>
                            <div className="data-row">
                                <div className="left-col v-r"> <div className="col-header">CANCELLED</div> <p>{item.cancel_time ? item.cancel_time : 'NO'}</p> </div>
                                <div className="right-col v-r"> 
                                    {/* {!item.cancelled &&<p>{item.actions.length > 0 ? item.actions === 'error' ? 'Error: No link to online session' : `Online instructing starts in ${item.actions}` : ''}</p>} */}
                                    <div className={`v-c ${item.cancel_time ? '' : 'active'}`}>
                                        {!item.cancel_time ? 
                                            <React.Fragment>
                                                {this.checkLessonExpired(item.sched) ?
                                                    <div className="v-c" onClick={e => this.seeRecording(item)}>
                                                        <ReactSVG src={play_button} className="svg-icon"/> {item.recorded_online_link? "See Recording" : "No link to online session"}
                                                    </div>
                                                    :
                                                    <p>{`Online tutoring starts in ${moment(item.sched).local().format('MMM D, YYYY hh:mm a')}`}</p>
                                                }
                                            </React.Fragment>
                                            : 
                                            'No link to online session'
                                        }
                                    </div>
                                 </div>
                            </div>
                            {!this.checkLessonExpired(item.sched) && Math.floor((new Date(item.sched) - new Date()) / 1000) < 1800 && <button className="btn-2 btn-2-outline cancel-btn outline-btn note-btn" onClick={e => this.joinLesson(item)}>Join</button>}
                            {(!item.cancel_time && !this.checkLessonExpired(item.sched)) && <button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(item)}>Cancel</button>}
                            {/* {this.checkLessonExpired(item.start_time) && <button className={`btn-2 btn-2-outline cancel-btn outline-btn ${this.props.cancel_lesson_loading ? 'disabled' : ''}`} onClick={e => this.cancelLesson(item)}>
                                {(cancel_id === item.id && this.props.cancel_lesson_loading) ? <div className="spinner" /> : 'Cancel'}
                            </button>} */}
                        </div>)}
                    </div>
                </div>}
                {depth === 1 && <div className="pending-detail v-r">
                    <h1 className="show-web">Instructoring Session</h1>
                    {/* {(!selectedLesson.cancelled && seconds > 0) && <div className="notification show-mobile-flex v-c">
                        <p>Online instructing starts in {this.formatTime(seconds)}</p>
                        <button className="join-btn" onClick={e => this.joinLesson()}>Join</button>
                    </div>} */}
                    {selectedData.id > 0 && <div className="detail-list v-r">
                        <div className="detail-row v-c">
                            <div className="detail-title">Instructor</div>
                            <div className="detail-content name"><p style={{cursor:'pointer'}} onClick={e => this.navigateToInstructor(e, selectedData.tutor.id)}>{selectedData.tutor.name}</p></div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Start Time</div>
                            <div className="detail-content">{moment(selectedData.sched).format('MMM D, YYYY hh:mm a')}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Length</div>
                            <div className="detail-content">{selectedData.hours}</div>
                        </div>
                        {/* <div className="detail-row v-c">
                            <div className="detail-title">Online</div>
                            <div className="detail-content">Yes</div>
                        </div> */}
                        <div className="detail-row v-c">
                            <div className="detail-title">Booked on</div>
                            <div className="detail-content">{moment(selectedData.booked_on).local().format('MMM D, YYYY hh:mm a')}</div>
                        </div>
                        <div className="detail-row v-c">
                            <div className="detail-title">Cost</div>
                            <div className="detail-content">{`$${selectedData.amount}`}</div>
                        </div>
                        {(!selectedData.cancel_time && !this.checkLessonExpired(selectedData.sched)) && (
                            <div className="detail-row v-c">
                                <div className="detail-title">Cancellation</div>
                                <div className="detail-content"><button className="btn-2 btn-2-outline cancel-btn outline-btn" onClick={e => this.cancelLesson(selectedData)}>Cancel</button></div>
                            </div>
                        )}
                    </div>}
                    <h1> Description </h1>
                    <div className="group">
                        <h2>Client</h2>
                        <span>{selectedData.client_note}</span>
                    </div>
                    <div className="group">
                        <h2>Tutor:</h2>
                        <span>{selectedData.tutor_note}</span>
                    </div>
                </div>}
                <ConfirmModal
                    isOpenModal={this.state.isOpenModal}
                    deleteConfirm={(e, r) => this.deleteConfirm(e, r)}
                    cancelTitle = "Delete lesson?"
                    cancelLesson = {true}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        incomingLessonInfo: state.Lesson.info || {},

        pending_lesson_list: state.Lesson.pending_lesson_list,
        get_pending_lesson_loading: state.Lesson.get_pending_lesson_loading,
        cancel_lesson_success: state.Lesson.cancel_lesson_success,

        cancel_lesson_loading: state.Lesson.cancel_lesson_loading,
        cancel_lesson_message: state.Lesson.cancel_lesson_message,
        
    }
}

export default connect(mapStateToProps, { 
    toggleVideoModal, 
    toggleJoinLesson, 
    getPendingLesson, 
    cancelLesson 
})(withRouter(PendingLessonPage));