import { JOIN_LESSON_TOGGLE, 
  GET_PENDING_LESSON, 
  GET_COMPLETED_LESSON, 
  CANCEL_LESSON, 
  GET_ALL_COURSES, 
  SCHEDULE_LESSON,
  GET_AVAILABILITY
} from '../Type';

export const toggleJoinLesson = (isOpen, info = {}) => {
  return {
    type: JOIN_LESSON_TOGGLE,
    data: { isOpen, info }
  }
}

export const getPendingLesson = (timezone) => {
  return {
    type: GET_PENDING_LESSON,
    data: {timezone}
  }
}

export const getCompletedLesson = (timezone) => {
  return {
    type: GET_COMPLETED_LESSON,
    data: {timezone}
  }
}

export const cancelLesson = (data, lesson_id) => {
  return {
    type: CANCEL_LESSON,
    data: {data, lesson_id }
  }
}

export const getAllCourses = () => {
  return {
    type: GET_ALL_COURSES,
    data: {}
  }
}

export const scheduleLesson = (data) => {
  return {
    type: SCHEDULE_LESSON,
    data: data
  }
}

export const getAvailability = (data) => {
  return {
    type: GET_AVAILABILITY,
    data: data
  }
}