import React from 'react';
import './ReviewInstructorsPage.scss';
import search from '../../Assets/Icon/search.svg';
import { InputBox, Avatar } from '../../Components';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import send from '../../Assets/Icon/send.svg';
import { StarSVGViewBox, StarSVGIconPath } from '../../Constant';
import StarRatings from 'react-star-ratings';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getReviews, leaveReview } from '../../Redux/Actions';
import Helper from '../../Utils/Helper';

class ReviewInstructorsPage extends React.Component {
    state = {
        Instructors: [],
        Reviews: [],

        searchText: '',
        depth: 0,
        selectedInstructor: {},
        reviewText: '',
        editReview: false,

        rating: 0,
        presentation: 0,
    }

    componentDidMount() {
        this.props.getReviews();       
        this.setState({
            depth: this.props.depth
        })
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.leave_review_success && this.props.leave_review_success) {
            Helper.showToast('success', 3000, 'Review Updated');
            this.props.getReviews();            
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            depth: props.depth,
            Reviews: props.review_list,
        }
    }

    getInstructorReview = (instructor) => {
        let index = this.state.Reviews.findIndex(temp => temp.tutor_id === instructor.id);
        return this.state.Reviews[index] || {};
    }

    selectInstructor = (instructor) => {
        let temp = instructor || {};
        // let index = this.state.Reviews.findIndex(temp => temp.tutor_id === instructor.id);
        // temp.review = this.state.Reviews[index] || { description: '', created_at: '', rating: 0 }
        this.setState({ 
            selectedInstructor: temp,
            reviewText: temp.review_content,
            editReview: false,
            rating: temp.rating,
        });
        this.props.updateTitle('push', instructor.name, 2);
	}
	
	_handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.sendReview();
        }
    }

    sendReview = () => {
        let { selectedInstructor, reviewText, rating } = this.state;
        if (!reviewText) {
            Helper.showToast('error', 3000, 'Please type your review');
        }
        let param = {
            tutor_id: selectedInstructor.id,
            description: reviewText,
            rating,
            _method: 'PUT'
        }
        this.props.leaveReview(param);
    };

    render() {
        const { searchText, depth, selectedInstructor, reviewText, editReview, rating } = this.state;
        const list = this.props.review_list.filter(item => item.tutor.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        
        return (
            <div className="reviewinstructors-page">
                <div className={`instructor-list scroll-area v-r ${depth === 0 ? 'activate' : ''}`}>
                    <InputBox className="search-contact" value={searchText} icon={search} placeholder="Search..." onChange={val=>this.setState({searchText:val})} />
                    <div className="instructors v-r scoll-area">
                        {list.map((item, index) => <div className={`instructor-container ${selectedInstructor.id === item.id ? 'selected' : ''}`} key={index} onClick={e => this.selectInstructor(item)}>
                            <div className="instructor">
                                <Avatar info={item.tutor} className="avatar" />
                                <div className="info v-r">
                                    <div className="name-area v-c">
                                        <h4>{item.tutor.name}</h4> {item.review_content && <p>{moment(item.reviewed_on).format('DD MMM')}</p>}
                                    </div>
                                    <p>{item.review_content ? item.review_content : 'No Review'}</p>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className={`review-area scroll-area v-r ${depth === 1 ? 'activate' : ''}`}>
                    {depth === 1 && <div className="review-content v-r">
                        <p className="instructor-name show-web">{selectedInstructor.name}</p>
                        {(!selectedInstructor.review_content || editReview) && <div className="compose-review v-r">
                            <div className="star-area v-c h-c v-r">
                                <h4>{editReview ? 'Publish a new' : 'Edit'} review about <span>{selectedInstructor.tutor.name}</span></h4>
                                <p />
                                <StarRatings
                                    rating={rating}
                                    starDimension="32px"
                                    starSpacing="4.5px"
                                    starRatedColor="#23A4EF"
                                    starHoverColor="#23A4EF"
                                    starEmptyColor="#D3EDFC"
                                    svgIconViewBox={StarSVGViewBox}
                                    svgIconPath={StarSVGIconPath}
                                    changeRating={(val, name) => this.setState({[name]: val})}
                                    name="rating"
                                />
                            </div>
                            <div className="review-input v-c">
                                <input className="review-text" placeholder={`Type your review about ${selectedInstructor.tutor.name}`} value={reviewText} onKeyDown={this._handleKeyDown} onChange={e => this.setState({reviewText:e.target.value})} />
                                {this.props.leave_review_loading ? <div className="spinner" /> : <ReactSVG src={send} className="svg-icon" onClick={e => this.sendReview()}/>}
                            </div>
                        </div>}
                        {(selectedInstructor.review_content && !editReview) && <div className="view-review v-r">
                            <div className="review-message">
                                <img className="initial" src={selectedInstructor.student.avatar} alt="instructor" />
                                <div className="review-info v-r">
                                    <div className="explain">
                                        You submitted a review about <span>{selectedInstructor.tutor.name}</span> {moment(selectedInstructor.reviewed_on).format("hh:mm A")}
                                    </div>
                                    <div className="my-review">
                                        <div className="feedback-area">
                                            <div className="feedback v-c">
                                                <StarRatings
                                                    rating={selectedInstructor.rating}
                                                    starDimension="15px"
                                                    starSpacing="2px"
                                                    starRatedColor="#23A4EF"
                                                    starEmptyColor="#D3EDFC"
                                                    svgIconViewBox={StarSVGViewBox}
                                                    svgIconPath={StarSVGIconPath}
                                                />
                                            </div>
                                        </div>
                                        <p>{selectedInstructor.review_content}</p>
                                    </div>
                                    <div className="v-c btn-area">
                                        <button className="btn-2 btn-2-outline edit-btn" onClick={e => this.setState({editReview: true})}>Edit</button>
                                        {/* <button className={`btn-2 btn-2-outline del-btn ${this.props.leave_review_loading ? 'disabled' : ''}`} onClick={e => this.sendReview(true)}>
                                            {this.props.leave_review_loading ? <div className="spinner" /> : 'Delete' }
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {        
        review_list: state.Review.review_list,

        leave_review_loading: state.Review.leave_review_loading,
        leave_review_success: state.Review.leave_review_success,
    }
}

export default connect(mapStateToProps, {     
    getReviews, 
    leaveReview 
})(withRouter(ReviewInstructorsPage));