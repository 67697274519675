import { GET_REVIEW_LIST, LEAVE_REVIEW } from '../Type';

export const getReviews = () => {
    return {
        type: GET_REVIEW_LIST,
        data: {}
    }
}

export const leaveReview = (data) => {
    return {
        type: LEAVE_REVIEW,
        data: data
    }
}