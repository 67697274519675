import { Api } from './Api';

export function* updatePlan(data){
    const jsonData = yield Api.post("/subscriptions", data);
    return jsonData;
}

export function* getPlan() {
    const jsonData = yield Api.get("/plans");
    return jsonData;
}

export function* unsubscribePlan(plan_id) {
	const jsonData = yield Api.put(`/subscriptions/${plan_id}`, {status: 'inactive'})
	return jsonData;
}