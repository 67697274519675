import { Api } from './Api';

export function* getMessageUserList() {
    const jsonData = yield Api.get("/messages");
    return jsonData;
}

export function* getMessageDetail(id) {
    const jsonData = yield Api.get(`/messages?user_id=${id}`);
    return jsonData;
}

export function* sendMessage(data) {
    const jsonData = yield Api.formPost('/messages', data);
    return jsonData;
}




export function* getContacts() {
    const jsonData = yield Api.get("/chats/contacts");
    return jsonData;
}

export function* getUnreadMessages(chat_id) {
    const jsonData = yield Api.get(`/messages?chat_id=${chat_id}&get_after=last_read`);
    return jsonData;
}

export function* startNewChat(data) {
    const jsonData = yield Api.post('/chats', data);
    return jsonData;
}